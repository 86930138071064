<template>
  <div class="product-form product-form--translations row">
    <div class="w-100 col" v-if="isLoaded">
      <div class="row product-form--header">
        <label for="field-translation" class="checkbox">
          <input
            ref="checkbox-all-translations"
            id="field-translation"
            class="checkbox__input"
            type="checkbox"
            @click="checkAllTranslations()"
          />
          <span class="checkbox__checkmark"></span>
        </label>
        <button
          class="button button--small button--icon button--blue-dark"
          @click="getTranslations()"
          :disabled="!checkedTranslations.length"
        >
          <icon
            icon="plus"
            icon-classes="button__icon button__icon--left button__icon--white"
          />
          <span class="button__text">Tłumacz zaznaczone</span>
        </button>
      </div>

      <table class="items-table">
        <thead>
          <tr class="items-table__tr">
            <th class="items-table__th"></th>
            <th class="items-table__th"></th>
            <th class="items-table__th">#</th>
            <th class="items-table__th">Oryginalny tekst</th>
            <th class="items-table__th">Obecne Tłumaczenie</th>
            <th class="items-table__th">Nowe Tłumaczenie</th>
            <th class="items-table__th">Status</th>
            <th class="items-table__th">Tłumaczenie</th>
          </tr>
        </thead>
        <tbody
          v-for="(translation, indexTranslation) in productTranslations"
          :key="indexTranslation"
        >
          <Translation
            v-if="productPl.name"
            :translation="translation"
            :indexTranslation="indexTranslation"
            :productPolish="productPl"
            :product="product"
            @checkTranslation="checkTranslation"
            @unCheckCheckbox="unCheckCheckbox"
            :ref="'childComponent' + indexTranslation"
          ></Translation>
        </tbody>
      </table>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>
<script>
import Translation from "./Translation";
import Icon from "./../parts/Icon";

import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      checkedTranslations: [],
      isLoaded: true,
      productPl: {},
      productTranslations: [],
      simpleFields: [
        {
          name: "Nazwa",
          field_type: "input",
          field_name: "name",
        },
        {
          name: "Krótki opis",
          field_type: "editor",
          field_name: "description_short",
        },
        {
          name: "Długi opis",
          field_type: "editor",
          field_name: "description_long",
        },
      ],
    };
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
  },
  components: {
    Icon,
    Translation,
  },
  created() {
    this.getProductPl();
  },
  methods: {
    unCheckCheckbox(indexTranslation) {
      this.$refs["checkbox-all-translations"].checked = false;
      let index = this.checkedTranslations.indexOf(indexTranslation);
      if (index !== -1) {
        this.checkedTranslations.splice(index, 1);
      }
    },
    checkAllTranslations() {
      this.checkedTranslations = [];
      for (let i = 0; i < this.productTranslations.length; i++) {
        if (this.$refs["checkbox-all-translations"].checked) {
          this.$refs["childComponent" + i][0].changeCheckMark(true);
          this.checkedTranslations.push(i);
        } else {
          this.$refs["childComponent" + i][0].changeCheckMark(false);
        }
      }
    },
    getTranslations() {
      let translations = [];
      this.checkedTranslations.forEach((element) => {
        translations.push(this.productTranslations[element].polish_value);
      });

      var params = {
        translations: translations,
        language: this.product.locale,
      };
      axios
        .post(API_BASE_URL + "/translations", params)
        .then((response) => {

          let deepLTranslations = response.data;
          deepLTranslations.forEach((translation, index) => {
            var indexTranslations = this.checkedTranslations[index];

            this.$set(
              this.productTranslations[indexTranslations],
              "value",
              translation.text
            );
            this.$refs["childComponent" + indexTranslations][0].changeButtons();

          });
          this.checkedTranslations=[];
        })
        .catch((error) => {
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
    checkTranslation(indexTranslation, isCheck) {
      if (isCheck) {
        this.checkedTranslations.push(indexTranslation);
      } else {
        let index = this.checkedTranslations.indexOf(indexTranslation);
        if (index !== -1) {
          this.checkedTranslations.splice(index, 1);
        }
      }
    },
    createProductTranslations() {
      this.simpleFields.forEach((field) => {
        if (this.productPl[field.field_name]) {
          this.productTranslations.push({
            name: field.name,
            value: "",
            is_translated: this.product["is_translated_" + field.field_name],
            field_type: field.field_type,
            field_name: field.field_name,
            polish_value: this.productPl[field.field_name],
          });
        }
      });

      this.product.attributes.forEach((attribute, indexAttribute) => {
        if (
          attribute.field_type == "string" &&
          this.productPl.attributes[indexAttribute].option_value_string
        ) {
          this.productTranslations.push({
            name: attribute.name,
            value: "",
            is_translated: attribute.is_translated_option_value_string,
            deep_1: "attributes",
            deep_1_index: indexAttribute,
            field_type: "input",
            field_name: "option_value_string",
            polish_value:
              this.productPl.attributes[indexAttribute].option_value_string,
          });
        }
      });

      this.product.variants.forEach((variant, indexVariant) => {
        this.productTranslations.push({
          name: "Nazwa produktu",
          value: "",
          is_translated: variant.is_translated_name,
          deep_1: "variants",
          deep_1_index: indexVariant,
          field_type: "input",
          field_name: "name",
          polish_value: this.productPl.variants[indexVariant].name,
        });
        this.product.variants[indexVariant].attributes.forEach(
          (attribute, indexAttribute) => {
            if (
              attribute.field_type == "string" &&
              this.productPl.variants[indexVariant].attributes[indexAttribute]
                .option_value_string
            ) {
              this.productTranslations.push({
                name: attribute.name,
                value: "",
                is_translated: attribute.is_translated_option_value_string,
                deep_1: "variants",
                deep_1_index: indexVariant,
                deep_2: "attributes",
                deep_2_index: indexAttribute,
                field_type: "input",
                field_name: "option_value_string",
                polish_value:
                  this.productPl.variants[indexVariant].attributes[
                    indexAttribute
                  ].option_value_string,
              });
            }
          }
        );
      });
    },
    getProductPl() {
      this.isLoaded = false;
      const headers = {
        headers: {
          locale: "pl",
        },
      };
      axios
        .get(API_BASE_URL + "/products/" + this.$route.params.id, headers)
        .then((response) => {
          this.productPl = response.data;
          this.createProductTranslations();
          this.isLoaded = true;
        })
        .catch((error) => {
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
  },
};
</script>
