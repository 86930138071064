<template>
  <div class="product-form product-form--basic-info row">
    <div class="col-24">
      <div>
        <div v-for="(variant, index) in product.variants" :key="variant.id">
          <VariantItem
            :variant="variant"
            :index="index"
            :dictionaries="dictionaries"
            @deleteVariant="deleteVariant"
            @changes-variant="changesVariant"
          ></VariantItem>
        </div>

        <button
          class="button button--icon"
          @click="addVariant(), $root.$emit('productIsChanged')"
        >
          <icon
            icon="plus"
            icon-classes="button__icon button__icon--left icon--white icon__plus--small"
          />
          <span class="button__text">Dodaj produkt</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "../parts/Icon";
import VariantItem from "./VariantItem.vue";

export default {
  data() {
    return {};
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
    dictionaries: {
      type: Object,
      required: true,
    },
    variantAttributesNew: {
      type: Array,
      require: false,
    },
  },
  components: { Icon, VariantItem },
  mounted() {
    this.$root.$on("saveProduct", () => {
      this.storeProduct();
    });
  },
  beforeDestroy() {
    this.$root.$off("saveProduct");
  },
  methods: {
    addVariant() {
      var variantAttributesData = null;
      variantAttributesData = [...this.variantAttributesNew];
      variantAttributesData.forEach((attribute) => {
        attribute.option_value_string = null;
        attribute.option_value_ids = null;
        attribute.option_value_boolean = false;
        attribute.spim_category_id = this.product.spim_category_id;
        attribute.is_translated_option_value_string = 0;
      });

      var variant = {
        id: null,
        product_id: this.product.id,
        ean13: null,
        is_acctive: 0,
        is_new: 0,
        length: null,
        width: null,
        height: null,
        weight: null,
        //translate
        name: null,
        is_translated_name: 0,
        attributes: [...this.variantAttributesNew],
      };
      this.product.variants.push(variant);
    },
    deleteVariant(index) {
      this.product.variants.splice(index, 1);
    },
    changesVariant(variant, index) {
      this.$set(this.product.variants, index, variant);
    },
  },
};
</script>
