<template>
  <div>
    <nested-draggable :categories="categories" />
  </div>
</template>
<script>
import nestedDraggable from "./CategoriesNested";
export default {
  props: ["cat", "categories"],
  name: "categories-list",
  components: {
    nestedDraggable,
  },
  methods: {
    async addCategory(categoryId) {
      this.$root.$emit("addCategory", categoryId);
    },
    async editCategory(cat) {
      let object = {
        category: cat,
      };
      this.$root.$emit("editCategory", object);
    },
    async deleteCategory(category) {
      this.$root.$emit("deleteCategory", category);
    },
  },
};
</script>
