<template>
  <div>
    <dictionary-modal-table
      dictionary_link="/languages"
      title="Lista języków"
      :numeral_text="['język', 'języki', 'języków']"
      :columns_title="['name', 'code', 'currency', 'default']"
    ></dictionary-modal-table>
  </div>
</template>

<script>
import DictionaryModalTable from "./DictionaryModalTable.vue";
export default {
  components: {
    DictionaryModalTable,
  },
};
</script>
