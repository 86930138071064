<template>
  <div class="col-24">
    <div class="row">
      <RolledSection>
        <div slot="header">{{ title }}</div>
        <div slot="body" class="product-form--gray pl-3 pr-3 pb-3 mb-4">
          <table class="items-table" v-if="product.similar_products.length > 0">
            <thead>
              <tr class="items-table__tr">
                <th class="items-table__th" style="width: 200px">Symbol</th>
                <th class="items-table__th">Nazwa</th>
                <th class="items-table__th"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="items-table__tr"
                v-for="product in product.similar_products"
                :key="product.id"
              >
                <td class="items-table__td">
                  <div class="form-box__mobile-element">Symbol</div>
                  {{ product.symbol }}
                </td>
                <td class="items-table__td">
                  <div class="form-box__mobile-element">Nazwa</div>
                  <router-link
                    class="items-table__link"
                    v-bind:to="{
                      name: 'productEdit',
                      params: { id: product.id },
                    }"
                    >{{ product.name }}
                  </router-link>
                </td>
                <td class="items-table__td items-table__td--button">
                  <div class="form-box__mobile-element">Akcje</div>
                  <div class="d-flex justify-content-end">
                    <button
                      class="button-icon button-icon--ml-10"
                      @click="deleteConnect(product)"
                    >
                      <icon icon="unlink" icon-classes="icon--grey" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-start" v-else>
            <table class="items-table">
              <tr class="items-table__tr">
                <td class="items-table__td">
                  <span class="items-table__noitems">Brak pozycji.</span>
                </td>
              </tr>
            </table>
          </div>
          <div class="d-flex justify-content-end pt-4">
            <button
              class="button icon__icons-wrap button--grey"
              @click="openModal()"
            >
              <icon
                icon="link"
                icon-classes="button__icon button__icon--left icon--regular"
              />
              <span>Powiąż</span>
            </button>
          </div>
        </div>
      </RolledSection>
    </div>

    <!-- Modal -->
    <modal :show="showModal" @close="showModal = !showModal">
      <div
        slot="header"
        class="d-flex flex-column flex-xl-row justify-content-xl-between align-items-xl-center w-100"
      >
        <div>{{ title }}</div>
        <searcher
          @searchObjects="searchProducts"
          @getObjects="getProducts"
        ></searcher>
      </div>

      <div slot="body">
        <div v-if="!loadingProducts">
          <table class="items-table" v-if="products.data.length > 0">
            <thead>
              <tr class="items-table__tr">
                <th class="items-table__th">Symbol</th>
                <th class="items-table__th">Nazwa</th>
                <th class="items-table__th pr-0 text-right">Akcje</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="items-table__tr"
                v-for="product in products.data"
                :key="product.id"
              >
                <td class="items-table__td">
                  <div class="form-box__mobile-element">Symbol</div>
                  {{ product.symbol }}
                </td>
                <td class="items-table__td">
                  <div class="form-box__mobile-element">Nazwa</div>
                  {{ product.name }}
                </td>
                <td class="items-table__td items-table__td--button">
                  <div class="form-box__mobile-element">Akcje</div>
                  <div class="d-flex justify-content-end">
                    <button
                      class="button-icon"
                      v-if="checkIsConnected(product)"
                      @click="deleteConnect(product)"
                    >
                      <icon icon="unlink" />
                    </button>
                    <button
                      class="button-icon"
                      v-else
                      @click="addConnect(product)"
                    >
                      <icon icon="link" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else>Brak produktów w bazie.</div>

          <pagination
            :pagination="products"
            @paginate="getProducts()"
            :offset="1"
          ></pagination>
        </div>
        <div v-else class="d-flex align-items-center">
          <div
            class="loader button__icon button__icon--left d-inline-block"
          ></div>
          <div>Trwa wczytywanie produktów...</div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "./../parts/Modals/Modal";
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import _ from "lodash";
import Icon from "../parts/Icon";
import Pagination from "./../parts/Pagination";
import Searcher from "../parts/Forms/Searcher.vue";
import RolledSection from "./forms/RolledSection.vue";

export default {
  data: () => ({
    showModal: false,
    products: {
      loading: true,
    },
    loadingProducts: true,
    localProduct: false,
    connectedProducts: {},
    connectedProductsIds: [],
    search: "",
    order: "asc",
    orderBy: "name",
  }),
  props: {
    type: {
      type: String,
      default: "",
      required: true,
    },
    title: {
      type: String,
      default: "---",
    },
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    Modal,
    Pagination,
    Icon,
    Searcher,
    RolledSection,
  },
  methods: {
    checkIsConnected(chosenProduct) {
      return this.product.similar_products.some(
        (elem) => elem.id === chosenProduct.id
      );
    },
    async getProducts() {
      this.loadingProducts = true;
      try {
        var params = {
          page: this.products.current_page,
          order: this.order,
          perPage: 5,
          exclude: [this.$route.params.id],
          search: this.search
        };

        const response = await axios.get(API_BASE_URL + "/products", {
          params: params,
        });
        this.products = response.data;
        this.loadingProducts = false;
      } catch (e) {
        this.loadingProducts = false;
      }
    },
    searchProducts: _.debounce(function (search) {
      this.search = search;
      this.products.current_page = 1;
      this.getProducts();
    }, 400),
    openModal() {
      this.getProducts();
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.search = "";
    },

    async addConnect(chosenProduct) {
      this.product.similar_products.push({
        id: chosenProduct.id,
        symbol: chosenProduct.symbol,
        name: chosenProduct.name,
      });
      this.$root.$emit("productIsChanged");
    },
    async deleteConnect(chosenProduct) {
      const filtersList = this.product.similar_products.filter(
        (element) => element.id !== chosenProduct.id
      );
      this.product.similar_products = filtersList;
      this.$root.$emit("productIsChanged");
    },
  },
};
</script>
