<template>
    <div>
        <div class="d-flex justify-content-end language-switcher">
            <div class="language-switcher__lang" :class="{ 'is-active' : $i18n.locale == lang.code }"
                v-for="lang in languages" :key="'lang_' + lang.code" @click="switchLang(lang)">
                {{ lang.code | uppercase }}
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

    export default {
        data: () => ({
            languages: []
        }),
        created() {
            let vm = this;

            axios.get(API_BASE_URL + '/languages').then(function (response) {
                vm.languages = response.data;
            }).catch(function (error) {
                vm.$root.$emit('noticesBox', error.response.data);
            });
        },
        methods: {
            switchLang(lang) {
                this.$store.dispatch('language/setLanguage', lang);
            }
        }
    };
</script>