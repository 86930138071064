<template>
  <div>
    <content-top
      v-show="categories.length"
      title="Lista kategorii"
      :subheading="
        `${categories.length} 
          ${$options.filters.numeralsText(
            categories.length,
            'kategoria',
            'kategorie',
            'kategorii'
          )}`
      "
    ></content-top>
    <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
      <spim-loader></spim-loader>
      <add-button
        @addObject="addCategory"
        textButton="Dodaj"
        :icon="true"
      ></add-button>
      <div class="content col-24 mt-4">
        <div>
          <categories-list-draggable
            v-if="categories"
            :categories="categories"
            @addCategory="addCategory"
            @editCategory="editCategory"
            @deleteCategory="deleteCategory"
          >
          </categories-list-draggable>
        </div>
      </div>
    </div>

    <!-- Modal edit -->
    <modal
      :show="showEditModal"
      size="md"
      @close="showEditModal = !showEditModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Edytuj kategorię {{ "#" + editingCategory.id }}
      </div>

      <div slot="body">
        <div class="row">
          <div class="col-24">
            <div class="product-form__title">Logo</div>

            <input
              type="file"
              ref="photosInput"
              @change="onImageChange"
              :disabled="isStoring"
              style="display: none;"
            />

            <button
              v-if="!imagePath"
              class="button button--icon mb-3"
              @click="$refs.photosInput.click()"
              :disabled="isStoring"
            >
              <icon
                v-if="!isStoring"
                icon="plus"
                icon-classes="icon--white button__icon button__icon--left"
              >
              </icon>
              <div v-else class="loader button__icon button__icon--left"></div>
              <span class="button__text">Dodaj logo</span>
            </button>

            <div class="product-photo mb-0" v-if="imagePath">
              <img :src="imagePath" />

              <div
                class="product-photo__actions d-flex align-items-center justify-content-end"
              >
                <button
                  class="button-icon button-icon--ml-10 button-icon--bg-white"
                  :disabled="isStoring"
                  @click="$refs.photosInput.click()"
                >
                  <icon icon="edit" />
                </button>
                <button
                  class="button-icon button-icon--ml-10 button-icon--bg-white"
                  :disabled="isStoring"
                  @click="deleteImage()"
                >
                  <icon icon="trash" />
                </button>
              </div>
            </div>
          </div>

          <div class="col-24">
            <div class="form-box">
              <label class="form-box__label form-box__label--mt">
                Nazwa:
              </label>
              <input v-model="editingCategory.name" class="form-box__input" />

              <label class="form-box__label form-box__label--mt">
                Krótka nazwa:
              </label>
              <input
                v-model="editingCategory.short_name"
                class="form-box__input"
              />

              <label class="form-box__label form-box__label--mt"> Opis: </label>
              <textarea
                class="product-form__input product-form__input--textarea"
                v-model="editingCategory.description"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button class="button button--grey" @click="showEditModal = false">
          Anuluj
        </button>
        <button
          class="button button--icon button--ml-10"
          @click="updateCategory()"
        >
          <div
            class="loader button__icon button__icon--left"
            v-if="editingCategory.isSaving"
          ></div>
          <icon
            v-else
            icon="save"
            icon-classes="button__icon button__icon--left icon--white icon__plus--small"
          />
          <span class="button__text">Zapisz</span>
        </button>
      </div>
    </modal>

    <!-- Modal add -->
    <modal :show="showAddModal" size="md" @close="showAddModal = !showAddModal">
      <div slot="header" class="notice-modal__header--title">
        <template v-if="newCategory.parent_id">
          Dodaj podkategorię dla kategorii {{ "#" + newCategory.parent_id }}
        </template>
      </div>

      <div slot="body">
        <div class="form-box">
          <label class="form-box__label"> Nowa kategoria nazwa: </label>
          <input
            v-model="newCategory.name"
            class="form-box__input"
            @keypress.enter.prevent="storeCategory()"
          />
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button class="button button--grey" @click="showAddModal = false">
          Anuluj
        </button>
        <button
          class="button button--icon button--ml-10"
          @click="storeCategory()"
        >
          <icon
            icon="plus"
            icon-classes="button__icon button__icon--left icon--white icon__plus--small"
          />
          <span class="button__text">Dodaj nową kategorię</span>
        </button>
      </div>
    </modal>

    <!-- Modal delete -->
    <modal
      :show="showDeleteModal"
      size="md"
      @close="showDeleteModal = !showDeleteModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Usuń kategorię
      </div>

      <div slot="body">
        <div>
          Napewno chcesz usunąć kategorię
          <strong>{{
            "#" + objectToDelete.id + " - " + objectToDelete.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showDeleteModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDeleteCategory()"
        >
          Tak
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import Icon from "./../parts/Icon";
import Modal from "./../parts/Modals/Modal";
import CategoriesListDraggable from "./../parts/CategoriesListDraggable";
import SpimLoader from "../parts/SpimLoader.vue";
import ContentTop from "../parts/ContentTop.vue";
import AddButton from "../parts/buttons/addButton.vue";

export default {
  data() {
    return {
      isLoading: false,
      categoriesOrder: null,
      categories: [],
      newCategory: {},
      editingCategory: {},
      showDeleteModal: false,
      showAddModal: false,
      showEditModal: false,
      objectToDelete: {},
      parentsSelect: [],
      imagePath: false,
      isStoring: false,
    };
  },
  async created() {
    this.getCategories();
  },
  components: {
    Icon,
    Modal,
    CategoriesListDraggable,
    ContentTop,
    SpimLoader,
    AddButton,
  },
  mounted() {
    this.$root.$on("addCategory", (values) => {
      this.addCategory(values.categoryId, values.subcategories);
    });
    this.$root.$on("editCategory", (category) => {
      this.editCategory(category);
    });
    this.$root.$on("deleteCategory", (category) => {
      this.deleteCategory(category);
    });
  },
  beforeDestroy() {
    this.$root.$off("addCategory");
    this.$root.$off("editCategory");
    this.$root.$off("deleteCategory");
  },
  watch: {
    categories: {
      async handler() {
        if (!this.categoriesOrder) {
          this.categoriesOrder = [];
          return;
        }

        this.categories.forEach((category, key) => {
          this.categoriesOrder.push({
            id: category.id,
            parent_id: null,
            order: key,
          });
          this.getSubcategories(category);
        });

        let formData = new FormData();
        formData.append("categories", JSON.stringify(this.categoriesOrder));

        try {
          const response = await axios.post(
            API_BASE_URL + "/categories/order-update",
            formData
          );
          this.categoriesOrder = null;
          this.categories = response.data;
          this.$root.$emit("noticesBox", {
            type: "success",
            message: "Zaktualizowano kolejność kategorii",
          });
        } catch (error) {
          this.$root.$emit("noticesBox", error.response.data);
        }
      },
      deep: true,
    },
  },
  methods: {
    getSubcategories(category) {
      if (!category.subcategories.length) return null;

      category.subcategories.forEach((item, key) => {
        this.categoriesOrder.push({
          id: item.id,
          parent_id: category.id,
          order: key,
        });
        this.getSubcategories(item);
      });
    },
    async getCategories() {
      this.isLoading = true;
      try {
        const response = await axios.get(API_BASE_URL + "/categories");
        this.categories = response.data || [];
        this.isLoading = false;
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
        this.isLoading = false;
      }
    },
    async storeCategory() {
      var data = this.newCategory;
      try {
        const response = await axios.post(API_BASE_URL + "/categories", data);
        this.newCategory.name = "";
        this.categories = response.data;
        this.editingCategory.isSaving = false;
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Pomyślnie dodano kategorię",
        });
        this.categoriesOrder = null;
        this.showAddModal = false;
      } catch (error) {
        this.editingCategory.isSaving = false;
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async updateCategory() {
      this.editingCategory.isSaving = true;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData = this.editingCategory;
      formData.image_path = this.editingCategory.image_path
        ? this.editingCategory.image_path
        : null;
      try {
        const response = await axios.post(
          API_BASE_URL + "/categories/" + formData.id + "?_method=PUT",
          formData,
          config
        );
        this.newCategory.name = "";
        this.categories = response.data;
        this.editingCategory.isSaving = false;
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Pomyślnie dodano kategorię",
        });
        this.categoriesOrder = null;
      } catch (error) {
        this.editingCategory.isSaving = false;
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    addCategory(categoryId, order) {
      this.showAddModal = true;
      this.newCategory.parent_id = categoryId;
      this.newCategory.order = !order ? this.categories.length : order;
      this.imagePath = false;
    },
    editCategory(object) {
      let category = object.category;

      // show edit modal
      this.parentsSelect = this.categories;
      this.parentsSelect.forEach((item, index) => {
        index;
        if (
          typeof item.subcategories == "undefined" ||
          item.id == category.id
        ) {
          // this.parentsSelect.splice(index, 1);
        } else if (
          (item.subcategories && item.subcategories.length == 0) ||
          (item.subcategories &&
            item.subcategories.length == 1 &&
            item.subcategories[0].id == category.id)
        ) {
          // item.subcategories = undefined;
        } else {
          this.changeEmptyArrayToUndefinedRecursive(
            item.subcategories,
            category.id
          );
        }
      });

      this.editingCategory.id = category.id;
      this.editingCategory.name = category.name;
      this.editingCategory.short_name = category.short_name;
      this.editingCategory.description = category.description;
      this.editingCategory.parent_id = category.parent_id;
      this.editingCategory.order = category.order;
      this.editingCategory.image_path = category.image_path;

      if (category.image_path) {
        this.imagePath = this.getApiPath(category.image_path);
      } else {
        this.imagePath = null;
      }

      this.showEditModal = true;
    },
    deleteCategory(category) {
      this.showDeleteModal = true;
      this.objectToDelete = category;
    },
    async confirmDeleteCategory() {
      this.showDeleteModal = false;
      try {
        await axios.delete(
          API_BASE_URL + "/categories/" + this.objectToDelete.id
        );
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Katagoria została usunięta",
        });
        this.getCategories();
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    changeEmptyArrayToUndefinedRecursive(items, categoryId) {
      let flat = [];

      items.forEach((item, index) => {
        index;

        if (item.id == categoryId) {
          // items.splice(index, 1);
          // item.subcategories = undefined;
        } else if (
          (item.subcategories && item.subcategories.length == 0) ||
          (item.subcategories &&
            item.subcategories.length == 1 &&
            item.subcategories[0].id == categoryId)
        ) {
          // item.subcategories = undefined;
        } else if (
          typeof item.subcategories != "undefined" &&
          Array.isArray(item.subcategories) &&
          item.subcategories.length > 0
        ) {
          flat.push(
            ...this.changeEmptyArrayToUndefinedRecursive(
              item.subcategories,
              categoryId
            )
          );
        } else {
          flat.push(item);
        }
      });

      return flat;
    },
    onImageChange(e) {
      this.editingCategory.image_path = null;
      this.editingCategory.image = e.target.files[0];
      this.imagePath = URL.createObjectURL(this.editingCategory.image);

      this.$refs.photosInput.value = "";
    },
    deleteImage() {
      this.editingCategory.image_path = null;
      this.imagePath = false;
    },
    getApiPath(path) {
      if (path) {
        let timestamp = new Date().getTime();

        return API_BASE_URL + "/" + path + "?" + timestamp;
      } else return require("../../img/default.png");
    },
  },
};
</script>
