<template>
  <div class="row">
    <RolledSection>
      <div slot="header">Produkt {{ index + 1 }} #{{ variantLocal.index }}</div>
      <div slot="button">
        <div
          class="button-icon button-icon--ml-10"
          @click="deleteVariant(index), $root.$emit('productIsChanged')"
        >
          <icon icon="trash" icon-classes="icon--grey button-icon--gray" />
        </div>
      </div>
      <div slot="body" class="row product-form--gray pb-4 mb-4">
        <RolledSection>
          <div slot="header">Informacje podstatowe</div>
          <div slot="body" class="row">
            <div class="col-24 col-xl-8">
              <InputSimple
                :object="variantLocal"
                label="Nazwa*"
                fieldName="name"
                type="text"
                @changes="setNewVariantValue"
              ></InputSimple>
            </div>
            <div class="col-24 col-xl-8">
              <InputSimple
                :object="variantLocal"
                label="Kod EAN-13"
                fieldName="ean13"
                type="text"
              ></InputSimple>
            </div>
            <div class="col-24 col-xl-8">
              <InputSimple
                :object="variantLocal"
                label="Symbol"
                fieldName="symbol"
                type="text"
              ></InputSimple>
            </div>
            <div class="col-24 col-xl-8">
              <InputSimple
                :object="variantLocal"
                label="ALTERNATYWNY SYMBOL - NOTATKA"
                fieldName="alternate_symbol"
                type="text"
              ></InputSimple>
            </div>
            <div class="col-24 col-xl-8">
              <InputSimple
                :object="variantLocal"
                label="Cena katalogowa"
                fieldName="list_price"
                type="text"
              ></InputSimple>
            </div>
          </div>
        </RolledSection>
        <RolledSection>
          <div slot="header">Dane dodatkowe</div>
          <div slot="body" class="row">
            <div class="col-24 col-xl-8">
              <Checkbox
                :object="variantLocal"
                label="Wyprzedaż"
                fieldName="is_sale"
              ></Checkbox>
            </div>
            <div class="col-24 col-xl-8">
              <Checkbox
                :object="variantLocal"
                label="Status"
                fieldName="status"
              ></Checkbox>
            </div>
            <div class="col-24 col-xl-8">
              <Checkbox
                :object="variantLocal"
                label="Nowość"
                fieldName="is_new"
              ></Checkbox>
            </div>
            <div class="col-24 col-xl-8">
              <Checkbox
                :object="variantLocal"
                label="Czy wycofane?"
                fieldName="is_withdrawn"
              ></Checkbox>
            </div>
            <div class="col-24 col-xl-8">
              <Checkbox
                :object="variantLocal"
                label="Czy brak w katalogu?"
                fieldName="is_not_in_catalog"
              ></Checkbox>
            </div></div
        ></RolledSection>
        <RolledSection>
          <div slot="header">Dane logistyczne</div>
          <div slot="body" class="row">
            <div class="col-24 col-xl-8">
              <InputSimple
                :object="variantLocal"
                label="Wymiar katalogowy"
                fieldName="catalog_dimensions"
                type="text"
              ></InputSimple>
            </div>
            <div class="col-24 col-xl-8">
              <InputSimple
                :object="variantLocal"
                label="Długość [cm]"
                fieldName="length"
                type="number"
              ></InputSimple>
            </div>
            <div class="col-24 col-xl-8">
              <InputSimple
                :object="variantLocal"
                label="Szerokość [cm]"
                fieldName="width"
                type="number"
              ></InputSimple>
            </div>
            <div class="col-24 col-xl-8">
              <InputSimple
                :object="variantLocal"
                label="Wysokość [cm]"
                fieldName="height"
                type="number"
              ></InputSimple>
            </div>
            <div class="col-24 col-xl-8">
              <InputSimple
                :object="variantLocal"
                label="Waga [kg]"
                fieldName="weight"
                type="number"
              ></InputSimple>
            </div></div
        ></RolledSection>
        <RolledSection>
          <div slot="header">Lista atrybutów</div>
          <div slot="body">
            <div class="col-24" v-if="variantLocal.attributes.length">
              <table class="items-table">
                <thead>
                  <tr class="items-table__tr">
                    <th class="items-table__th items-table__th--expand">
                      nazwa
                    </th>
                    <th class="items-table__th">wartość</th>
                  </tr>
                </thead>
                <tbody
                  v-for="(attribute, indexAttribute) in variantLocal.attributes"
                  :key="attribute.id"
                >
                  <VariantItemAttribute
                    :attribute="attribute"
                    :index="indexAttribute"
                    :index-variant="index"
                    @set-new-attribute="setNewAttribute"
                  >
                  </VariantItemAttribute>
                </tbody>
              </table>
            </div>
            <div class="col-24" v-else>
              Brak dostępnych atrybutów w tej kategorii
            </div>
          </div></RolledSection
        >
      </div>
    </RolledSection>
  </div>
</template>
<script>
import Icon from "../parts/Icon";
import InputSimple from "./forms/InputSimple.vue";
import Checkbox from "./forms/Checkbox.vue";
import RolledSection from "./forms/RolledSection.vue";
import VariantItemAttribute from "./VariantItemAttribute.vue";

export default {
  data() {
    return {
      isLoading: false,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
      variantLocal: this.variant,
    };
  },
  props: {
    variant: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      require: false,
    },
    dictionaries: {
      type: Object,
      required: true,
    },
  },
  components: {
    Icon,
    InputSimple,
    Checkbox,
    RolledSection,
    VariantItemAttribute,
  },
  watch: {
    variant: {
      handler() {
        this.variantLocal = this.variant;
      },
      deep: true,
    },
  },
  methods: {
    deleteVariant(index) {
      this.$emit("deleteVariant", index);
    },
    setNewAttribute(attribute, index) {
      let newVariantLocale = { ...this.variantLocal };
      this.$set(newVariantLocale.attributes, index, attribute);
      this.$emit("changes-variant", newVariantLocale, this.index);
    },
    setNewVariantValue() {
      this.$emit("changes-variant", this.variantLocal, this.index);
    },
  },
};
</script>
