import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

class AuthService {
    login(user) {
        return axios
            .post(API_BASE_URL + '/login', {
                email: user.email,
                password: user.password
            })
            .then(this.handleResponse)
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout(user) {
        if (!user) {
            localStorage.removeItem('user');
            return true;
        } else {
            var token = (typeof user.token !== 'undefined') ? user.token : '';
            
            return axios
                .post(API_BASE_URL + '/logout', {
                    token: token
                })
                .then(response => {
                    if (response.data.success) {
                        localStorage.removeItem('user');
                    }

                    return response.data;
                });
        }
    }

    handleResponse(response) {
        if (response.status === 401) {
            localStorage.removeItem('user');
            location.reload(true);

            const error = response.data && response.data.message;
            return Promise.reject(error);
        }

        return Promise.resolve(response);
    }
}

export default new AuthService();