var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-form__input-wrap"},[_c('div',{staticClass:"product-form__title"},[_vm._v(_vm._s(_vm.label))]),(_vm.options.length > 0)?_c('treeselect',{staticClass:"vue-treeselect",attrs:{"multiple":_vm.multiple,"options":_vm.options,"placeholder":"","normalizer":_vm.normalizer,"clearable":_vm.clearable,"disable-branch-nodes":_vm.disableBranchNodes,"value-consists-of":_vm.valueConsistsOf,"disabled":_vm.disabled},on:{"input":function($event){_vm.$root.$emit('productIsChanged'),
        _vm.$emit('additionalFunction', _vm.object),
        _vm.checkProperty()}},model:{value:(_vm.object[_vm.fieldName]),callback:function ($$v) {_vm.$set(_vm.object, _vm.fieldName, $$v)},expression:"object[fieldName]"}}):_c('div',{staticClass:"product-form__note",domProps:{"textContent":_vm._s(
      _vm.isLoading
        ? 'Ładowanie...'
        : 'Nie dodano jeszcze tego elementu do słowników.'
    )}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }