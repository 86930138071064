<template>
  <div class="mr-2">
    <button
      class="button button--text button--icon"
      @click.prevent="$root.$emit('changeShow')"
    >
      <icon icon="filter" icon-classes="button__icon button__icon--left" />
      <span
        class="button__text"
        v-text="show ? changeTextButton : textButton"
      ></span>
    </button>
  </div>
</template>
<script>
import Icon from "../Icon.vue";

export default {
  components: {
    Icon,
  },
  props: {
    textButton: {
      type: String,
      require: true,
    },
    changeTextButton: {
      type: String,
      require: true,
    },
    show: {
      type: Boolean,
      require: true,
    },
  },
};
</script>
