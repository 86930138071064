<template>
  <div>
    <dictionary-modal-table
      dictionary_link="/order-times"
      title="Lista czasów oczekiwania na zmówienie"
      :numeral_text="[
        'czas oczekiwania na zmówienie',
        'czasy oczekiwania na zmówienie',
        'czasów oczekiwania na zmówienie',
      ]"
      :columns_title="['name']"
    ></dictionary-modal-table>
  </div>
</template>

<script>
import DictionaryModalTable from "./DictionaryModalTable.vue";
export default {
  components: {
    DictionaryModalTable,
  },
};
</script>
