<template>
  <div class="simple-search">
    <input
      type="text"
      :class="['simple-search__input', inputClass]"
      @input="$emit('searchObjects', search)"
      placeholder="Wyszukaj..."
      v-model="search"
    />
    <button class="simple-search__button" @click="$emit('getObjects')">
      <icon icon="search" />
    </button>
  </div>
</template>
<script>
import Icon from "../Icon.vue";

export default {
  data() {
    return {
      search: "",
    };
  },
  props: {
    inputClass: {
      type: String,
      require: true,
    },
  },
  components: {
    Icon,
  },
};
</script>
