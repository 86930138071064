<template>
  <div class="login">
    <form class="login__wrap" name="form" @submit.prevent="handleReset">
      <div class="login__header">
        <div class="login__header--logo">
          <img class="login__img login__img--spim" src="../img/spim.png" />
          <img
            class="login__img login__img--cellfast"
            src="../img/new_trendy_logo.png"
          />
        </div>
        <div class="login__header--title">
          <span>Zresetuj hasło do systemu</span>
        </div>
      </div>

      <div class="login__form">
        <div class="login__form--wrap">
          <label class="login__form--label" for="email">Email</label>
          <input
            class="login__form--input"
            type="text"
            name="username"
            v-model="email"
            :readonly="loading"
            required
          />
        </div>
        <div class="login__form--wrap">
          <button class="button" :disabled="loading">
            <span>Resetuj hasło </span>
          </button>
        </div>
        <div class="login__form--wrap">
          <div class="login__form--remind">
            Pamiętasz hasło?
            <router-link class="link" :to="{ name: 'login' }"
              >Zaloguj się</router-link
            >
          </div>
        </div>
      </div>

      <div class="login__footer">
        <span class="login__footer--content">
          powered by
          <a href="https://mda.pl/" title="MDA" target="_blank">
            <img class="site-footer__img" src="../img/mda.png" />
          </a>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push({
        name: "dashboard",
      });
    }
  },
  methods: {
    async handleReset() {
      this.loading = true;

      try {
        let response = await axios.post(API_BASE_URL + "/reset-password", {
          email: this.email,
        });

        this.$root.$emit("noticesBox", {
          type: "success",
          message: response.data.message,
        });

        this.$router.push({
          name: "login",
        });
      } catch (error) {
        this.loading = false;

        this.$root.$emit("noticesBox", error.response.data);
      }
    },
  },
};
</script>
