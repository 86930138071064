import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';

Vue.use(VueLocalStorage);

export const language = {
    namespaced: true,
    state: {
        language: Vue.localStorage.get('language'),
    },
    mutations: {
        SET_LANGUAGE(state, lang) {
            Vue.localStorage.set('language', lang.code);
            state.language = lang.code;

            if (!lang.preventReload) {
                location.reload();
            }
        },
    },
    actions: {
        setLanguage({commit}, lang) {
            commit('SET_LANGUAGE', lang);
        }
    },
};