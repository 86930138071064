export const DictionaryMenu = [
  {
    id: "dictionariesLanguages",
    label: "Języki",
  },
  {
    id: "dictionariesAttributes",
    label: "Atrybuty",
  },
  {
    id: "dictionariesFileTypes",
    label: "Typy plików",
  },
  {
    id: "dictionariesLines",
    label: "Linie produktów",
  },
  {
    id: "dictionariesMarks",
    label: "Marki",
  },
  {
    id: "dictionariesCollections",
    label: "Kolekcje",
  },
  {
    id: "dictionariesSeries",
    label: "Serie",
  },
  {
    id: "dictionariesOrderTimes",
    label: "Czas oczekiwania na zmówienie",
  },
  {
    id: "dictionariesCategories",
    label: "Kategorie",
  },
  {
    id: "dictionariesSpimCategories",
    label: "Kategorie SPIM",
  },
  {
    id: "dictionariesWarranties",
    label: "Gwarancje",
  },
  {
    id: "dictionariesMarkets",
    label: "Rynki",
  },
];
