export function editOrCreateLang(lang, type, product) {
    let vm = this;
    if (type == "addTranslation") {
      vm.$root.$emit("addProductLanguageModal", {
        translation: lang.locale,
        addTranslationTo: product.id,
      });
    } else if (type == "productEdit") {
      vm.$store
        .dispatch("language/setLanguage", {
          code: lang.locale,
          preventReload: true,
        })
        .then(function() {
          vm.$router.push({
            name: "productEdit",
            params: {
              id: product.id,
              locale: lang.locale,
            },
          });
        });
    }
  }