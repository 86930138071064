<template>
  <div>
    <dictionary-modal-table
      dictionary_link="/series"
      title="Lista serii"
      :numeral_text="['Seria', 'Serie', 'Serii']"
      :columns_title="['name']"
    ></dictionary-modal-table>
  </div>
</template>

<script>
import DictionaryModalTable from "./DictionaryModalTable.vue";
export default {
  components: {
    DictionaryModalTable,
  },
};
</script>
