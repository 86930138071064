<template>
  <div>
    <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
      <spim-loader></spim-loader>
      <div v-if="logs.data.length > 0" class="content">
        <table class="items-table">
          <thead class="items-table__thead">
            <tr class="items-table__tr">
              <th class="items-table__th">#</th>

              <th class="items-table__th">Data</th>

              <th class="items-table__th">Użytkownik</th>

              <th class="items-table__th">Akcja</th>

              <th class="items-table__th">Obiekt</th>

              <th class="items-table__th pr-0 text-right">Akcje</th>
            </tr>
          </thead>

          <tbody class="items-table__tbody">
            <template v-for="log in logs.data">
              <tr class="items-table__tr" v-bind:key="log.id">
                <td class="items-table__td">
                  {{ log.id }}
                </td>

                <td class="items-table__td">
                  <div class="form-box__mobile-element">Data</div>
                  {{ log.updated_at | prettyDate(true) }}
                </td>

                <td class="items-table__td">
                  <div class="form-box__mobile-element">Użytkownik</div>
                  <span
                    v-text="
                      log.user
                        ? log.user.email
                        : getEmail(log)
                        ? getEmail(log)
                        : '-'
                    "
                  ></span>
                </td>

                <td class="items-table__td">
                  <div class="form-box__mobile-element">Akcja</div>
                  {{ log.description }}
                </td>

                <td class="items-table__td">
                  <div class="form-box__mobile-element">Obiekt</div>
                  <span
                    v-text="
                      log.subject_id != null
                        ? log.subject_type + '#' + log.subject_id
                        : log.subject_type
                        ? log.subject_type
                        : '-'
                    "
                  ></span>
                </td>

                <td class="items-table__td pr-0">
                  <div class="form-box__mobile-element">Akcje</div>
                  <div class="d-flex justify-content-end">
                    <button
                      class="button-icon"
                      v-if="hasComparison(log)"
                      @click="showComparison(log)"
                    >
                      <icon icon="preview"></icon>
                    </button>
                    <button
                      class="button-icon"
                      v-else
                      @click="showPreview(log)"
                    >
                      <icon icon="preview"></icon>
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!-- // Products list-->
      <div v-else class="content">Brak wyników.</div>
    </div>

    <!-- Pagination -->
    <pagination
      v-if="havePagination"
      :pagination="logs"
      @paginate="$emit('getObjects')"
      :offset="1"
    ></pagination>
    <!-- Pagination -->

    <!-- Modal delete -->
    <modal :show="showModal" size="lg" @close="showModal = !showModal">
      <div slot="header" class="notice-modal__header--title">
        Podgląd logu #{{ previewLog.id }}
      </div>

      <div slot="body">
        <div class="row" v-if="previewLog.preview">
          <vue-json-pretty
            :showLength="true"
            :data="previewLog.preview"
            :highlightMouseoverNode="true"
          ></vue-json-pretty>
        </div>
        <div v-else class="row">
          <div class="col-24 col-xl-12" v-if="previewLog.old">
            <span v-text="previewLog.new ? 'Przed:' : ''"></span>
            <vue-json-pretty
              :showLength="true"
              :data="previewLog.old"
              :highlightMouseoverNode="true"
            ></vue-json-pretty>
          </div>
          <div class="col-24 mt-5 mt-xl-0 col-xl-12" v-if="previewLog.new">
            <span v-text="previewLog.old ? 'Po:' : ''"></span>
            <vue-json-pretty
              :showLength="true"
              :data="previewLog.new"
              :highlightMouseoverNode="true"
            ></vue-json-pretty>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import Pagination from "../parts/Pagination";
import Icon from "../parts/Icon.vue";
import Modal from "../parts/Modals/Modal";
import VueJsonPretty from "vue-json-pretty";
import SpimLoader from "../parts/SpimLoader.vue";

export default {
  data() {
    return {
      showModal: false,
      previewLog: {},
    };
  },
  props: {
    logs: {
      type: Object,
      required: true,
    },
    isLoading: { type: Boolean, required: true },
    havePagination: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Pagination,
    Icon,
    Modal,
    VueJsonPretty,
    SpimLoader,
  },
  methods: {
    showComparison(data) {
      this.previewLog.preview = false;
      let array = JSON.parse(data.properties);
      this.previewLog.id = data.id;

      if (typeof array.old != "undefined") {
        this.previewLog.old = array.old;
      } else {
        this.previewLog.old = false;
      }

      if (typeof array.attributes != "undefined") {
        this.previewLog.new = array.attributes;
      } else {
        this.previewLog.new = false;
      }
      this.showModal = true;
    },
    hasComparison(data) {
      let array = JSON.parse(data.properties);
      return (
        typeof array.old != "undefined" ||
        typeof array.attributes != "undefined"
      );
    },
    getEmail(data) {
      let array = JSON.parse(data.properties);
      return array.email ? array.email : false;
    },
    showPreview(data) {
      this.previewLog.old = false;
      this.previewLog.new = false;
      let array = JSON.parse(data.properties);
      this.previewLog.id = data.id;
      this.previewLog.preview = array;
      this.showModal = true;
    },
  },
};
</script>
