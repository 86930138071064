<template>
  <div class="col-24">
    <div
      class="product-form__header  product-form__header--gray d-flex align-items-center"
    >
      <slot name="icon-beafore-title"></slot>
      <div
        :class="({ 'is-hidden': isHidden }, 'p-2')"
        @click.prevent="isHidden = !isHidden"
      >
        <icon
          icon="plus"
          v-if="!isHidden"
          icon-classes="icon__plus--large icon--black icon--rotated"
        />
        <icon icon="plus" v-else icon-classes="icon__plus--large icon--black" />
      </div>
      <span class="product-form__header-title"
        ><slot name="header"></slot
      ></span>
      <slot name="button"></slot>
    </div>
    <transition name="transition-slide" v-if="!isHidden">
      <slot name="body"></slot>
    </transition>
  </div>
</template>
<script>
import Icon from "../../parts/Icon.vue";
export default {
  data() {
    return { isHidden: false };
  },
  components: {
    Icon,
  },
};
</script>
