<template>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    v-show="show"
    @click="$emit('close')"
  >
    <div
      class="modal-dialog"
      :class="'modal-' + size"
      role="document"
      @click.stop
    >
      <div class="modal__content modal-content">
        <div class="modal__close" @click="$emit('close')">
          <icon icon="delete" icon-classes="modal__close-icon"></icon>
        </div>
        <div class="modal__header modal-header">
          <slot name="header"></slot>
        </div>
        <div class="modal__body modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal__footer modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "bootstrap/js/src/modal";
import Icon from "../Icon";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "xl",
    },
  },
  components: {
    Icon,
  },
  mounted() {
    this.modalInstance = new Modal(this.$el);
    document.addEventListener("keydown", this.handleKeydown);
  },
  data() {
    return {
      modalInstance: null,
    };
  },
  watch: {
    show(value) {
      if (value) {
        this.open();
      } else if (!value) {
        this.close();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeydown);
    if (this.isDef(this.modalInstance)) {
      this.modalInstance.dispose();
      this.modalInstance = null;
    }
  },
  methods: {
    handleKeydown(e) {
      if (this.show && e.keyCode === 27) {
        this.$emit("close");
      }
    },
    close() {
      if (this.isDef(this.modalInstance)) {
        this.modalInstance.hide();
      }
    },
    open() {
      if (this.isDef(this.onOpen)) {
        this.$emit("open");
      }
      this.modalInstance.show();
    },
    isDef(obj) {
      return typeof obj !== undefined && obj !== null;
    },
  },
};
</script>
