<template>
  <div class="content-top row">
    <div class="col-24 content-top__wrap">
      <div class="row content-top__title-box">
        <div class="col-24 d-flex mb-3">
          <h2 class="content-top__heading" v-if="title">{{ title }}</h2>
          <div class="content-top__subheading" v-if="subheading">
            {{ subheading }}
          </div>
        </div>
        <div class="col-24 d-flex">
          <addButton
            v-if="addButton"
            textButton="Dodaj"
            :icon="true"
            @addObject="addObject"
          ></addButton>

          <addButton
            v-if="addAdditionalButton"
            :textButton="textAdditionalButton"
            :disabledAdditionalButton="disabledAdditionalButton"
            @addObject="addAdditionalAction"
          ></addButton>

          <ChangeButton
            v-if="textChangeButton"
            :changeTextButton="changeTextChangeButton"
            :textButton="textChangeButton"
            :show="show"
          >
          </ChangeButton>
        </div>
      </div>

      <searcher
        v-if="serchable"
        @searchObjects="searchObjects"
        @getObjects="getObjects"
      ></searcher>

      <searcher-date
        v-if="serchableDate"
        @getObjects="getObjects"
      ></searcher-date>
    </div>
  </div>
</template>
<script>
import Searcher from "./Forms/Searcher.vue";
import SearcherDate from "./Forms/SearcherDate.vue";
import addButton from "../parts/buttons/addButton.vue";
import ChangeButton from "./buttons/changeButton.vue";
export default {
  data() {
    return {
      search: "",
    };
  },
  props: {
    title: {
      type: String,
      require: true,
    },
    serchable: {
      type: Boolean,
      require: false,
    },
    serchableDate: {
      type: Boolean,
      require: false,
    },
    subheading: {
      type: String,
      require: false,
    },
    addButton: {
      type: Boolean,
      require: false,
    },
    addAdditionalButton: {
      type: Boolean,
      require: false,
    },
    textAdditionalButton: {
      type: String,
      require: false,
    },
    disabledAdditionalButton: {
      type: Boolean,
      require: false,
    },
    changeTextChangeButton: {
      type: String,
      require: false,
    },
    textChangeButton: {
      type: String,
      require: false,
    },
    show: {
      type: Boolean,
      require: false,
    },
  },
  components: {
    Searcher,
    SearcherDate,
    addButton,
    ChangeButton,
  },
  methods: {
    searchObjects(search) {
      this.$emit("searchObjects", search);
    },
    getObjects(date_from=null, date_to=null) {
      this.$emit("getObjects", date_from, date_to);
    },
    addObject() {
      this.$emit("addObject");
    },
    addAdditionalAction() {
      this.$emit("addAdditionalAction");
    },
  },
};
</script>
