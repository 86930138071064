<template>
  <div class="content">
    <Header title="Aktywność użytkowników"></Header>
    <div class="content__body">
      <content-top :serchableDate="true" @getObjects="getLogs"></content-top>

      <ActivityLogTable
        @getObjects="getLogs"
        :logs="logs"
        :isLoading="isLoading"
        :havePagination="true"
      ></ActivityLogTable>
    </div>
  </div>
  <!-- // Products -->
</template>

<script>
import ContentTop from "./parts/ContentTop.vue";
import Header from "./parts/Header.vue";
import ActivityLogTable from "./activity-log/ActivityLogTable.vue";
import moment from "moment";
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      isLoading: true,
      logs: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1,
        data: {},
      },
      dateFrom:null,
      dateTo:null,
      showModal: false,
      previewLog: {},
    };
  },
  components: {
    ContentTop,
    Header,
    ActivityLogTable,
  },
  mounted() {
    this.getLogs();
  },
  methods: {
    async getLogs(date_from = null, date_to = null) {
      if (date_from) {
        this.dateFrom = moment(date_from).format("YYYY-MM-DD");
      }
      if (date_to) {
        this.dateTo = moment(date_to).format("YYYY-MM-DD");
      }
      this.isLoading = true;
      try {
        var params = {
          page: this.logs.current_page,
          date_from: this.dateFrom,
          date_to: this.dateTo,
        };

        const response = await axios.get(API_BASE_URL + "/logs", {
          params: params,
        });

        this.logs = response.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
  },
};
</script>
