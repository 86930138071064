<template>
  <div class="content">
    <Header title="Słowniki"></Header>
    <div class="content__body">
      <div id="">
        <template v-if="$route.name == 'dictionariesList'">
          <div class="content mb-2">Wybierz słownik z menu poniżej.</div>
          <treeselect
            class="w-100"
            v-model="selectedDictionary"
            placeholder="Wybierz słownik"
            :multiple="false"
            :options="DictionaryMenu"
            :clearable="false"
            @input="changeRoute"
          />
        </template>
        <router-view :key="$route.path"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect";
import { DictionaryMenu } from "@/componentsData/dictionaryMenu.js";
import Header from "../parts/Header.vue";

export default {
  data() {
    return {
      normalizer(node) {
        return {
          id: node.id,
          label: node.label,
        };
      },
      DictionaryMenu,
    };
  },
  components: {
    Treeselect,
    Header,
  },
  created() {
    this.changeSelectedDictionary();
  },
  watch: {
    $route() {
      this.changeSelectedDictionary();
    },
  },
  methods: {
    changeSelectedDictionary() {
      if (this.$route.name != "dictionariesList") {
        this.selectedDictionary = this.$route.name;
      } else {
        this.selectedDictionary = null;
      }
    },
    changeRoute() {
      this.$router.push({ name: this.selectedDictionary });
    },
  },
};
</script>
