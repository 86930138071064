<template>
  <modal :show="showModal" @close="closeModal()" size="md">
    <div
      slot="header"
      class="d-flex justify-content-between align-items-center w-100"
    >
      Dodaj nowe tłumaczenie
    </div>

    <div slot="body">
      <div class="col-24" v-if="!isStored">
        <div class="row">
          <div class="col-24">
            <div class="product-form__title">Nazwa</div>
            <input
              class="product-form__input"
              type="text"
              v-model="newProduct.name"
              @keypress.enter.prevent="storeProduct()"
            />
          </div>
          <div class="col-24 modal__notice">
            <template
              v-if="modalData.addTranslationTo && modalData.translation"
            >
              Dodajesz nowe tłumaczenie ({{
                modalData.translation | uppercase
              }}) do wariantu #{{ modalData.addTranslationTo }}
            </template>
          </div>
        </div>
      </div>
      <div v-else>
        Czy chcesz przejść do ekranu edycji wariantu
        <strong>{{ isStored.name }}</strong
        >?
      </div>
    </div>

    <div slot="footer" class="d-flex align-items-center">
      <button
        class="button button--grey"
        @click="closeModal()"
        :disabled="isStoring"
      >
        Anuluj
      </button>
      <button
        v-if="!isStored"
        class="button button--icon button--ml-10"
        @click="storeProduct()"
        :disabled="isStoring"
      >
        <div
          class="loader button__icon button__icon--left"
          v-if="isStoring"
        ></div>
        <icon
          v-else
          icon="plus"
          icon-classes="button__icon button__icon--left icon--white icon__plus--small"
        />
        <span class="button__text">Dodaj nowy wariant</span>
      </button>
      <button
        v-else
        class="button button--icon button--ml-10"
        @click="
          goToEditProduct(modalData.translation, modalData.addTranslationTo)
        "
      >
        <icon
          icon="edit"
          icon-classes="button__icon button__icon--left icon--white icon__plus--small"
        />
        <span class="button__text">Przejdź do edycji</span>
      </button>
    </div>
  </modal>
</template>

<script>
import Icon from "../Icon.vue";
import Modal from "./Modal.vue";
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  mounted() {
    this.$root.$on("addProductLanguageModal", (data) => {
      this.showModal = true;
      this.modalData = data;
    });
  },
  data() {
    return {
      showModal: false,
      modalData: {},
      newProduct: {},
      isStoring: false,
      isStored: false,
    };
  },
  components: {
    Modal,
    Icon,
  },
  beforeDestroy() {
    this.$root.$off("addProductLanguageModal");
  },
  methods: {
    async storeProduct() {
      let data = {};
      this.isStoring = true;

      if (this.newProduct.name) data.name = this.newProduct.name;
      data.locale = this.modalData.translation
        ? this.modalData.translation
        : this.$i18n.locale;
      if (this.modalData.addTranslationTo)
        data.product_id = this.modalData.addTranslationTo;

      axios
        .post(API_BASE_URL + "/products-add-language", data)
        .then((response) => {
          this.$root.$emit("noticesBox", {
            type: "success",
            message: "Poprawnie dodano nowe tłumacznie wariantu.",
          });

          this.newProduct = {};
          this.isStoring = false;
          this.isStored = response.status == 200 ? 1 : 0;
          this.$root.$emit("addProductLanguageModalSuccess");
        })
        .catch((error) => {
          this.isStoring = false;
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
    closeModal() {
      this.showModal = false;
      this.newProduct = {};
      this.isStoring = false;
      this.isStored = false;
    },
    goToEditProduct(lang, id) {
      let vm = this;
      this.closeModal();
      vm.$store
        .dispatch("language/setLanguage", {
          code: lang,
          preventReload: true,
        })
        .then(function () {
          vm.$router.push({
            name: "productEdit",
            params: {
              id: id,
              locale: lang,
            },
          });
        });
      // this.modalData = {};
    },
  },
};
</script>
