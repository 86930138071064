var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentUser)?_c('header',{staticClass:"navbar"},[_c('div',{staticClass:"navbar__wrap"},[_c('ul',{staticClass:"navbar__menu navbar__menu--navigation"},[_c('li',{staticClass:"navbar__item"},[_c('router-link',{staticClass:"navbar__link",attrs:{"to":{ name: 'dashboard' }}},[_c('img',{staticClass:"navbar__logo",attrs:{"src":require("../../iconfont/logo.svg")}})])],1),_c('li',{staticClass:"navbar__item"},[_c('router-link',{staticClass:"navbar__link",class:{ 'is-current': _vm.$route.name == 'dashboard' },attrs:{"to":{ name: 'dashboard' }}},[_vm._v("Pulpit")])],1),_c('li',{staticClass:"navbar__item"},[_c('router-link',{staticClass:"navbar__link",class:{
            'is-current':
              _vm.$route.name == 'productsList' ||
              _vm.$route.meta.submenu == 'products',
          },attrs:{"to":{ name: 'productsList' }}},[_vm._v("Warianty i produkty")])],1),_c('li',{staticClass:"navbar__item"},[_c('router-link',{staticClass:"navbar__link",class:{
            'is-current':
              _vm.$route.name == 'files' || _vm.$route.meta.submenu == 'files',
          },attrs:{"to":{ name: 'files' }}},[_vm._v("Pliki")])],1),_c('li',{staticClass:"navbar__item"},[_c('router-link',{staticClass:"navbar__link",class:{
            'is-current':
              _vm.$route.name == 'dictionariesList' ||
              _vm.$route.meta.submenu == 'dictionaries',
          },attrs:{"to":{ name: 'dictionariesList' }}},[_vm._v("Słowniki")])],1),_c('li',{staticClass:"navbar__item"},[_c('router-link',{staticClass:"navbar__link",class:{
            'is-current':
              _vm.$route.name == 'activityLog' || _vm.$route.meta.submenu == 'logs',
          },attrs:{"to":{ name: 'activityLog' }}},[_vm._v("Aktywność")])],1),(_vm.currentUser.role == 'admin')?_c('li',{staticClass:"navbar__item"},[_c('router-link',{staticClass:"navbar__link",class:{
            'is-current':
              _vm.$route.name == 'usersList' || _vm.$route.meta.submenu == 'users',
          },attrs:{"to":{ name: 'usersList' }}},[_vm._v("Użytkownicy")])],1):_vm._e()]),_c('ul',{staticClass:"navbar__menu navbar__menu--management"},[_c('li',{staticClass:"navbar__item navbar__item--management navbar__item--currentuser"},[_vm._v(" "+_vm._s(_vm.currentUser.name)+" ")]),_c('li',{staticClass:"navbar__item navbar__item--management"},[_c('router-link',{staticClass:"navbar__link",class:{ 'is-current-icon': _vm.$route.name == 'accountEdit' },attrs:{"to":{ name: 'accountEdit' }}},[_c('icon',{attrs:{"icon":"settings","icon-classes":""}})],1)],1),_c('li',{staticClass:"navbar__item navbar__item--management"},[_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.handleLogout.apply(null, arguments)}}},[_c('icon',{attrs:{"icon":"log-out","icon-classes":"navbar__log-out-icon"}})],1)]),_c('li',{staticClass:"navbar__item navbar__item--management"},[_c('div',{staticClass:"mobile-menu__burger",class:{ close: _vm.isOpened },on:{"click":function($event){_vm.isOpened = !_vm.isOpened}}},[_c('div',{staticClass:"mobile-menu__burger-line"}),_c('div',{staticClass:"mobile-menu__burger-line"}),_c('div',{staticClass:"mobile-menu__burger-line"})])])])]),_c('div',{staticClass:"mobile-menu",class:{ 'is-opened': _vm.isOpened }},[_c('ul',{staticClass:"mobile-menu__items"},[_c('li',{staticClass:"mobile-menu__item"},[_c('router-link',{staticClass:"mobile-menu__link",attrs:{"to":{ name: 'dashboard' }},nativeOn:{"click":function($event){_vm.isOpened = !_vm.isOpened}}},[_c('img',{staticClass:"mobile-menu__logo",attrs:{"src":require("../../iconfont/logo.svg")}})])],1),_c('li',{staticClass:"mobile-menu__item"},[_c('router-link',{staticClass:"mobile-menu__link",class:{ 'is-current': _vm.$route.name == 'dashboard' },attrs:{"to":{ name: 'dashboard' }},nativeOn:{"click":function($event){_vm.isOpened = !_vm.isOpened}}},[_vm._v("Pulpit")])],1),_c('li',{staticClass:"mobile-menu__item"},[_c('router-link',{staticClass:"mobile-menu__link",class:{
            'is-current':
              _vm.$route.name == 'productsList' ||
              _vm.$route.meta.submenu == 'products',
          },attrs:{"to":{ name: 'productsList' }},nativeOn:{"click":function($event){_vm.isOpened = !_vm.isOpened}}},[_vm._v("Warianty i produkty")])],1),_c('li',{staticClass:"mobile-menu__item"},[_c('router-link',{staticClass:"mobile-menu__link",class:{
            'is-current':
              _vm.$route.name == 'files' || _vm.$route.meta.submenu == 'files',
          },attrs:{"to":{ name: 'files' }},nativeOn:{"click":function($event){_vm.isOpened = !_vm.isOpened}}},[_vm._v("Pliki")])],1),(_vm.currentUser.role == 'admin')?_c('li',{staticClass:"mobile-menu__item"},[_c('router-link',{staticClass:"mobile-menu__link",class:{
            'is-current':
              _vm.$route.name == 'dictionariesList' ||
              _vm.$route.meta.submenu == 'dictionaries',
          },attrs:{"to":{ name: 'dictionariesList' }},nativeOn:{"click":function($event){_vm.isOpened = !_vm.isOpened}}},[_vm._v("Słowniki")])],1):_vm._e(),(_vm.currentUser.role == 'admin')?_c('li',{staticClass:"mobile-menu__item"},[_c('router-link',{staticClass:"mobile-menu__link",class:{
            'is-current':
              _vm.$route.name == 'activityLog' || _vm.$route.meta.submenu == 'logs',
          },attrs:{"to":{ name: 'activityLog' }},nativeOn:{"click":function($event){_vm.isOpened = !_vm.isOpened}}},[_vm._v("Aktywność")])],1):_vm._e(),(_vm.currentUser.role == 'admin')?_c('li',{staticClass:"mobile-menu__item"},[_c('router-link',{staticClass:"mobile-menu__link",class:{
            'is-current':
              _vm.$route.name == 'usersList' || _vm.$route.meta.submenu == 'users',
          },attrs:{"to":{ name: 'usersList' }},nativeOn:{"click":function($event){_vm.isOpened = !_vm.isOpened}}},[_vm._v("Użytkownicy")])],1):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }