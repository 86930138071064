<template>
  <div class="product-form__input-wrap">
    <div class="product-form__title">{{ label }}</div>
    <input
      class="product-form__input"
      :type="type"
      v-model="object[fieldName]"
      @input="changeVaule()"
      :disabled="disabled"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    object: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    productChange: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    changeVaule() {
      if (this.productChange) {
        this.$root.$emit("productIsChanged");
      }
      this.$emit("changes", this.fieldName, this.object);
    },
  },
};
</script>
