<template>
  <header v-if="currentUser" class="navbar">
    <div class="navbar__wrap">
      <ul class="navbar__menu navbar__menu--navigation">
        <li class="navbar__item">
          <router-link class="navbar__link" :to="{ name: 'dashboard' }">
            <img class="navbar__logo" src="../../iconfont/logo.svg" />
          </router-link>
        </li>
        <li class="navbar__item">
          <router-link
            class="navbar__link"
            :class="{ 'is-current': $route.name == 'dashboard' }"
            :to="{ name: 'dashboard' }"
            >Pulpit</router-link
          >
        </li>
        <li class="navbar__item">
          <router-link
            class="navbar__link"
            :class="{
              'is-current':
                $route.name == 'productsList' ||
                $route.meta.submenu == 'products',
            }"
            :to="{ name: 'productsList' }"
            >Warianty i produkty</router-link
          >
        </li>
        <li class="navbar__item">
          <router-link
            class="navbar__link"
            :class="{
              'is-current':
                $route.name == 'files' || $route.meta.submenu == 'files',
            }"
            :to="{ name: 'files' }"
            >Pliki</router-link
          >
        </li>
        <li class="navbar__item">
          <router-link
            class="navbar__link"
            :class="{
              'is-current':
                $route.name == 'dictionariesList' ||
                $route.meta.submenu == 'dictionaries',
            }"
            :to="{ name: 'dictionariesList' }"
            >Słowniki</router-link
          >
        </li>
        <li class="navbar__item">
          <router-link
            class="navbar__link"
            :class="{
              'is-current':
                $route.name == 'activityLog' || $route.meta.submenu == 'logs',
            }"
            :to="{ name: 'activityLog' }"
            >Aktywność</router-link
          >
        </li>
        <li class="navbar__item" v-if="currentUser.role == 'admin'">
          <router-link
            class="navbar__link"
            :class="{
              'is-current':
                $route.name == 'usersList' || $route.meta.submenu == 'users',
            }"
            :to="{ name: 'usersList' }"
            >Użytkownicy</router-link
          >
        </li>
      </ul>

      <ul class="navbar__menu navbar__menu--management">
        <li
          class="navbar__item navbar__item--management navbar__item--currentuser"
        >
          {{ currentUser.name }}
        </li>
        <li class="navbar__item navbar__item--management">
          <router-link
            class="navbar__link"
            :class="{ 'is-current-icon': $route.name == 'accountEdit' }"
            :to="{ name: 'accountEdit' }"
          >
            <icon icon="settings" icon-classes="" />
          </router-link>
        </li>
        <li class="navbar__item navbar__item--management">
          <div @click.prevent="handleLogout">
            <icon icon="log-out" icon-classes="navbar__log-out-icon" />
          </div>
        </li>
        <li class="navbar__item navbar__item--management">
          <div
            class="mobile-menu__burger"
            :class="{ close: isOpened }"
            @click="isOpened = !isOpened"
          >
            <div class="mobile-menu__burger-line"></div>
            <div class="mobile-menu__burger-line"></div>
            <div class="mobile-menu__burger-line"></div>
          </div>
        </li>
      </ul>
    </div>
    <div :class="{ 'is-opened': isOpened }" class="mobile-menu">
      <ul class="mobile-menu__items">
        <li class="mobile-menu__item">
          <router-link
            class="mobile-menu__link"
            :to="{ name: 'dashboard' }"
            @click.native="isOpened = !isOpened"
          >
            <img class="mobile-menu__logo" src="../../iconfont/logo.svg" />
          </router-link>
        </li>
        <li class="mobile-menu__item">
          <router-link
            class="mobile-menu__link"
            @click.native="isOpened = !isOpened"
            :class="{ 'is-current': $route.name == 'dashboard' }"
            :to="{ name: 'dashboard' }"
            >Pulpit</router-link
          >
        </li>
        <li class="mobile-menu__item">
          <router-link
            class="mobile-menu__link"
            @click.native="isOpened = !isOpened"
            :class="{
              'is-current':
                $route.name == 'productsList' ||
                $route.meta.submenu == 'products',
            }"
            :to="{ name: 'productsList' }"
            >Warianty i produkty</router-link
          >
        </li>
        <li class="mobile-menu__item">
          <router-link
            class="mobile-menu__link"
            @click.native="isOpened = !isOpened"
            :class="{
              'is-current':
                $route.name == 'files' || $route.meta.submenu == 'files',
            }"
            :to="{ name: 'files' }"
            >Pliki</router-link
          >
        </li>
        <li class="mobile-menu__item" v-if="currentUser.role == 'admin'">
          <router-link
            class="mobile-menu__link"
            @click.native="isOpened = !isOpened"
            :class="{
              'is-current':
                $route.name == 'dictionariesList' ||
                $route.meta.submenu == 'dictionaries',
            }"
            :to="{ name: 'dictionariesList' }"
            >Słowniki</router-link
          >
        </li>
        <li class="mobile-menu__item" v-if="currentUser.role == 'admin'">
          <router-link
            class="mobile-menu__link"
            @click.native="isOpened = !isOpened"
            :class="{
              'is-current':
                $route.name == 'activityLog' || $route.meta.submenu == 'logs',
            }"
            :to="{ name: 'activityLog' }"
            >Aktywność</router-link
          >
        </li>
        <li class="mobile-menu__item" v-if="currentUser.role == 'admin'">
          <router-link
            class="mobile-menu__link"
            @click.native="isOpened = !isOpened"
            :class="{
              'is-current':
                $route.name == 'usersList' || $route.meta.submenu == 'users',
            }"
            :to="{ name: 'usersList' }"
            >Użytkownicy</router-link
          >
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import User from "../../models/user";
import Icon from "./Icon";

export default {
  data() {
    return {
      user: new User(),
      isOpened: false,
    };
  },
  components: {
    Icon,
  },
  mounted() {
    this.$root.$on("updateUsername", (name) => {
      this.currentUser.name = name;
    });
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("auth/logout", this.$store.state.auth.user).then(
        () => {
          this.$router.push("/login");
        },
        (error) => {
          this.loading = false;
          this.message = error.message;
        }
      );
    },
  },
};
</script>
