import Vue from 'vue'
import App from './App.vue';
import { router } from './router';
import store from './store/index';
import interceptorsSetup from './helpers/axios-interceptor';
import VueI18n from 'vue-i18n';
import ElementUI from 'element-ui';
import { ElementTiptapPlugin } from 'element-tiptap';
import 'element-tiptap/lib/index.css';

// Styles
import './scss/main.scss';

// Filters
import './filters/pretty-bytes';
import './filters/pretty-date';
import './filters/numerals-text';
import './filters/uppercase';
import './filters/lowercase';
import { i18n } from './i18n'
Vue.use(ElementUI);
Vue.use(ElementTiptapPlugin, {
  // lang: "zh",
  // spellcheck: false,
});
Vue.config.productionTip = false;

// Vue config
Vue.config.productionTip = false;

// Languages
Vue.use(VueI18n);

// Axios interceptors setup
interceptorsSetup();

// go VUE!
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');