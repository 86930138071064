<template>
  <draggable
    class="dragArea"
    tag="ul"
    :list="categories"
    :group="{ name: 'g1' }"
  >
    <li v-for="cat in categories" :key="cat.id">
      <div
        :class="
          !cat ? 'tree-list' : 'tree-list__item tree-list__item--draggable'
        "
      >
        <div v-if="!cat" class="tree-list__brand">
          <div class="tree-list__actions">
            <button
              class="button-icon"
              @click="addCategory(null)"
              data-tooltip="Dodaj"
            >
              <icon icon="plus" />
            </button>
          </div>
        </div>
        <div v-else class="tree-list__cat">
          <div class="d-flex">
            {{ parentId }}<icon icon="drag-indicator" />
            {{ "#" + cat.id + " " + cat.name }}
          </div>
          <div class="tree-list__actions">
            <button
              class="button-icon"
              @click="addCategory(cat.id, cat.subcategories.length)"
              data-tooltip="Dodaj"
            >
              <icon icon="plus" />
            </button>

            <button
              class="button-icon button-icon--ml-10"
              @click="editCategory(cat)"
              data-tooltip="Edytuj"
            >
              <icon icon="edit" />
            </button>

            <button
              class="button-icon button-icon--ml-10"
              @click="deleteCategory(cat)"
              data-tooltip="Usuń"
            >
              <icon icon="trash" />
            </button>
          </div>
        </div>
      </div>
      <nested-draggable :categories="cat.subcategories" :parent="cat.id" />
    </li>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
import Icon from "./Icon";
export default {
  data() {
    return {
      categoriesDragabble: [],
      test: null,
    };
  },
  props: {
    categories: {
      required: true,
      type: Array,
    },
    parentId: {
      type: Number,
    },
  },
  components: {
    Icon,
    draggable,
  },
  mounted() {
    this.test = this.categories;
  },
  methods: {
    async addCategory(categoryId, subcategories) {
      this.$root.$emit("addCategory", { categoryId, subcategories });
    },
    async editCategory(cat) {
      let object = {
        category: cat,
      };
      this.$root.$emit("editCategory", object);
    },
    async deleteCategory(category) {
      this.$root.$emit("deleteCategory", category);
    },
  },
  name: "nested-draggable",
};
</script>
