<template>
  <div class="product-form product-form--basic-info row">
    <div class="w-100" v-if="isLoaded">
      <RolledSection>
        <div slot="header">Informacje podstawowe</div>

        <div slot="body" class="row product-form--gray pb-4 mb-4">
          <div class="col-24 col-xl-8">
            <InputSimple
              :object="product"
              label="Nazwa*"
              fieldName="name"
              type="text"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8" v-if="!product.have_variants">
            <InputSimple
              :object="product"
              label="Kod EAN-13"
              fieldName="ean13"
              type="text"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8">
            <InputSimple
              :object="product"
              label="Symbol"
              fieldName="symbol"
              type="text"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8">
            <InputSimple
              :object="product"
              label="Alternatywny symbol -  notatka"
              fieldName="alternate_symbol"
              type="text"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8">
            <InputTreeselect
              :options="dictionaries.categories"
              :object="product"
              :isLoading="isLoading"
              fieldName="category_id"
              label="Kategoria*"
            ></InputTreeselect>
          </div>
          <div class="col-24 col-xl-8">
            <InputTreeselect
              :options="dictionaries.marks"
              :object="product"
              :isLoading="isLoading"
              fieldName="mark_id"
              label="Marka"
              :clearable="true"
            ></InputTreeselect>
          </div>
          <div class="col-24 col-xl-8">
            <InputTreeselect
              :options="dictionaries.series"
              :object="product"
              :isLoading="isLoading"
              fieldName="serie_id"
              label="Seria"
              :clearable="true"
            ></InputTreeselect>
          </div>
          <div class="col-24 col-xl-8">
            <InputTreeselect
              :options="dictionaries.collections"
              :object="product"
              :isLoading="isLoading"
              fieldName="collection_id"
              label="Kolekcja"
              :clearable="true"
            ></InputTreeselect>
          </div>
          <div class="col-24 col-xl-8">
            <InputTreeselect
              :options="dictionaries.lines"
              :object="product"
              :isLoading="isLoading"
              fieldName="line_id"
              label="Linia produktów"
              :clearable="true"
            ></InputTreeselect>
          </div>
          <div class="col-24 col-xl-8">
            <InputSimple
              :object="product"
              label="Strona w katalogu 2022"
              fieldName="page_in_catalog"
              type="text"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8">
            <InputSimple
              :object="product"
              label="Numer deklaracji"
              fieldName="declaration_number"
              type="text"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8">
            <InputSimple
              :object="product"
              label="Rok CE"
              fieldName="year_ce"
              type="text"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8">
            <InputSimple
              :object="product"
              label="Numer instrukcji"
              fieldName="instruction_number"
              type="text"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8">
            <InputTreeselect
              :options="dictionaries.warranties"
              :object="product"
              :isLoading="isLoading"
              fieldName="warranty_id"
              label="Gwarancja"
            ></InputTreeselect>
          </div>
          <div class="col-24 col-xl-8">
            <InputTreeselect
              :options="dictionaries.order_times"
              :object="product"
              :isLoading="isLoading"
              fieldName="order_time_id"
              label="Czasów oczekiwania na zmówienie"
            ></InputTreeselect>
          </div>
          <div class="col-24 col-xl-8" v-if="!product.have_variants">
            <InputSimple
              :object="product"
              label="Cena katalogowa"
              fieldName="list_price"
              type="text"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8">
            <InputTreeselect
              :options="dictionaries.markets"
              :object="product"
              :isLoading="isLoading"
              fieldName="markets"
              label="Rynki"
              :multiple="true"
              :clearable="true"
            ></InputTreeselect>
          </div>
        </div>
      </RolledSection>
      <RolledSection>
        <div slot="header">Opis</div>
        <div slot="body" class="row product-form--gray pb-4 mb-4">
          <div class="col-24">
            <EditorForm
              fieldName="description_short"
              :object="product"
              label="Krótki opis"
            >
            </EditorForm>
            <EditorForm
              fieldName="description_long"
              :object="product"
              label="Długi opis"
            >
            </EditorForm>
          </div>
        </div>
      </RolledSection>
      <RolledSection v-if="!product.have_variants">
        <div slot="header">Dane dodatkowe</div>
        <div slot="body" class="row product-form--gray pb-4 mb-4">
          <div class="col-24 col-xl-8" v-if="!product.have_variants">
            <Checkbox
              :object="product"
              label="Wyprzedaż"
              fieldName="is_sale"
            ></Checkbox>
          </div>
          <div class="col-24 col-xl-8" v-if="!product.have_variants">
            <Checkbox
              :object="product"
              label="Status"
              fieldName="status"
            ></Checkbox>
          </div>
          <div class="col-24 col-xl-8" v-if="!product.have_variants">
            <Checkbox
              :object="product"
              label="Nowość"
              fieldName="is_new"
            ></Checkbox>
          </div>
          <div class="col-24 col-xl-8" v-if="!product.have_variants">
            <Checkbox
              :object="product"
              label="Czy wycofane?"
              fieldName="is_withdrawn"
            ></Checkbox>
          </div>
          <div class="col-24 col-xl-8" v-if="!product.have_variants">
            <Checkbox
              :object="product"
              label="Czy brak w katalogu?"
              fieldName="is_not_in_catalog"
            ></Checkbox>
          </div>
        </div>
      </RolledSection>
      <RolledSection v-if="!product.have_variants">
        <div slot="header">Dane logistyczne</div>
        <div slot="body" class="row product-form--gray pb-4 mb-4">
          <div class="col-24 col-xl-8" v-if="!product.have_variants">
            <InputSimple
              :object="product"
              label="Wymiar katalogowy"
              fieldName="catalog_dimensions"
              type="text"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8" v-if="!product.have_variants">
            <InputSimple
              :object="product"
              label="Długość [cm]"
              fieldName="length"
              type="number"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8" v-if="!product.have_variants">
            <InputSimple
              :object="product"
              label="Szerokość [cm]"
              fieldName="width"
              type="number"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8" v-if="!product.have_variants">
            <InputSimple
              :object="product"
              label="Wysokość [cm]"
              fieldName="height"
              type="number"
            ></InputSimple>
          </div>
          <div class="col-24 col-xl-8" v-if="!product.have_variants">
            <InputSimple
              :object="product"
              label="Waga [kg]"
              fieldName="weight"
              type="number"
            ></InputSimple>
          </div>
        </div>
      </RolledSection>
      <ConnectProducts
        :product="product"
        type="similar"
        title="Warianty podobne"
      ></ConnectProducts>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>
<script>
import EditorForm from "./forms/EditorForm";
import ConnectProducts from "./ConnectProducts.vue";
import InputTreeselect from "./forms/InputTreeselect.vue";
import InputSimple from "./forms/InputSimple.vue";
import Checkbox from "./forms/Checkbox.vue";
import RolledSection from "./forms/RolledSection.vue";

export default {
  data() {
    return {
      isLoaded: true,
    };
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
    dictionaries: {
      type: Object,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  components: {
    EditorForm,
    ConnectProducts,
    InputTreeselect,
    InputSimple,
    Checkbox,
    RolledSection,
  },
  mounted() {
    this.$root.$on("saveProduct", () => {
      this.storeProduct();
    });
  },
  beforeDestroy() {
    this.$root.$off("saveProduct");
  },
  methods: {},
};
</script>
