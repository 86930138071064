<template>
  <div class="content">
    <Header title="Pulpit"></Header>
    <div class="content__body">
      <div class="dashboard">
        <div class="dashboard__content">
          <div class="row"><div class="dashboard__title">Skróty</div></div>
          <Shortcuts></Shortcuts>
          <div class="row">
            <div class="dashboard__title">Ostatnia Aktywność</div>
          </div>
          <ActivityLogTable
            @getObjects="getLogs"
            :logs="logs"
            :isLoading="isLoading"
            :havePagination="false"
          ></ActivityLogTable>

          <div class="row"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./parts/Header.vue";
import Shortcuts from "./dashboard/Shortcuts.vue";
import ActivityLogTable from "./activity-log/ActivityLogTable.vue";
import moment from "moment";
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      isLoading: true,
      logs: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1,
        data: {},
      },
    };
  },
  components: { Header, Shortcuts, ActivityLogTable },
  mounted() {
    this.getLogs();
  },
  methods: {
    async getLogs(date_from = null, date_to = null) {
      if (date_from) {
        date_from = moment(date_from).format("YYYY-MM-DD");
      }
      if (date_to) {
        date_to = moment(date_to).format("YYYY-MM-DD");
      }
      this.isLoading = true;
      try {
        var params = {
          page: this.logs.current_page,
          date_from: date_from,
          date_to: date_to,
        };

        const response = await axios.get(API_BASE_URL + "/logs", {
          params: params,
        });

        this.logs = response.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
  },
};
</script>
