<template>
  <div>
    <content-top
      v-show="objects.length"
      title="Lista atrybutów"
      :subheading="`${objects.length} 
          ${$options.filters.numeralsText(
            objects.length,
            'atrybut',
            'atrybuty',
            'atrybutów'
          )}`"
    ></content-top>
    <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
      <spim-loader></spim-loader>
      <add-button
        @addObject="addObject"
        textButton="Dodaj"
        :icon="true"
      ></add-button>
      <div class="content col-24">
        <table class="items-table items-table--shrink">
          <thead class="items-table__thead">
            <tr class="items-table__tr">
              <th class="items-table__th items-table__th--sm">ID</th>
              <th class="items-table__th">Nazwa</th>
              <th class="items-table__th">Widoczny przy wariantach</th>
              <th class="items-table__th">Typ pola</th>
              <th class="items-table__th">Pole wielokrotnego wyboru</th>
              <th class="items-table__th">Opcje</th>
              <th class="items-table__th p-0 text-right">Akcje</th>
            </tr>
          </thead>

          <tbody class="items-table__tbody">
            <template v-for="object in objects">
              <tr class="items-table__tr" v-bind:key="object.id">
                <td class="items-table__td items-table__td--sm">
                  <div class="form-box__mobile-element">Id</div>
                  {{ object.id }}
                </td>

                <td class="items-table__td">
                  <div class="form-box__mobile-element">Nazwa</div>
                  <div>
                    {{ object.name }}
                  </div>
                </td>

                <td class="items-table__td">
                  <div class="form-box__mobile-element">
                    Widoczny przy wariantach
                  </div>
                  <div>
                    {{ object.show_in_variants ? "TAK" : "NIE" }}
                  </div>
                </td>

                <td class="items-table__td">
                  <div class="form-box__mobile-element">Typ pola</div>
                  <div>
                    {{ object.field_type == "string" ? "Tekst" : null }}
                    {{ object.field_type == "boolean" ? "Prawda-fałsz" : null }}
                    {{ object.field_type == "select" ? "Lista" : null }}
                  </div>
                </td>

                <td class="items-table__td">
                  <div class="form-box__mobile-element">
                    Pole wielokrotnego wyboru
                  </div>
                  <div v-if="object.field_type == 'select'">
                    {{ object.multi_select ? "TAK" : "NIE" }}
                  </div>
                  <div v-else>nd.</div>
                </td>

                <td class="items-table__td">
                  <div class="form-box__mobile-element">Opcje</div>
                  <div v-if="object.field_type == 'select'">
                    <div
                      v-for="option in object.options"
                      v-bind:key="option.id"
                    >
                      {{ option.name }},
                    </div>
                  </div>
                  <div v-else>nd.</div>
                </td>
                <td class="items-table__td items-table__td--button">
                  <div class="form-box__mobile-element">Akcje</div>
                  <div class="d-flex justify-content-end">
                    <button
                      class="button-icon"
                      @click.prevent="editObject(object)"
                      data-tooltip="Edytuj"
                    >
                      <icon icon="edit" />
                    </button>

                    <button
                      class="button-icon button-icon--ml-10"
                      @click="deleteObject(object)"
                      data-tooltip="Usuń"
                    >
                      <icon icon="trash" />
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal delete -->
    <modal
      :show="showDeleteModal"
      size="md"
      @close="showDeleteModal = !showDeleteModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Usuwanie elementu
      </div>

      <div slot="body">
        <div>
          Czy napewno chcesz usunąć ten element
          <strong>{{
            "#" + objectToDelete.id + " - " + objectToDelete.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showDeleteModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDeleteObject()"
        >
          Tak
        </button>
      </div>
    </modal>

    <!-- Modal add/edit -->
    <modal :show="showEditAddModal" size="lg" @close="closeEditAddModal">
      <div slot="header" class="notice-modal__header--title">
        {{ addEditObject.id ? "Edycja elementu" : "Dodawanie elementu" }}
      </div>

      <div slot="body">
        <div class="row">
          <div class="col-24">
            <div class="form-box">
              <div class="mb-2">
                <label class="form-box__label form-box__label--mt">
                  Nazwa:
                </label>
                <input v-model="addEditObject.name" class="form-box__input" />
              </div>
              <div class="mb-2">
                <label class="form-box__label form-box__label--mt">
                  Widoczny przy wariantach:
                </label>
                <label
                  :for="'check-show_in_variants' + addEditObject.id"
                  class="form-box__checkbox"
                >
                  <input
                    :id="'check-show_in_variants' + addEditObject.id"
                    class="form-box__checkbox--input"
                    type="checkbox"
                    v-model="addEditObject.show_in_variants"
                  />
                  <span
                    class="form-box__checkbox-checkmark"
                    v-text="addEditObject.show_in_variants ? 'Tak' : 'Nie'"
                  ></span>
                </label>
              </div>
              <div class="mb-2">
                <label class="form-box__label form-box__label--mt">
                  Typ pola:
                </label>
                <treeselect
                  v-if="field_types.length > 0"
                  class="vue-treeselect"
                  v-model="addEditObject.field_type"
                  :multiple="false"
                  :options="field_types"
                  placeholder=""
                  :normalizer="normalizer"
                  :clearable="false"
                />
              </div>
              <div class="mb-2" v-if="addEditObject.field_type == 'select'">
                <label class="form-box__label form-box__label--mt">
                  Pole wielokrotnego wyboru:
                </label>
                <label
                  :for="'check-multi_select' + addEditObject.id"
                  class="form-box__checkbox"
                >
                  <input
                    :id="'check-multi_select' + addEditObject.id"
                    class="form-box__checkbox--input"
                    type="checkbox"
                    v-model="addEditObject.multi_select"
                  />
                  <span
                    class="form-box__checkbox-checkmark"
                    v-text="addEditObject.multi_select ? 'Tak' : 'Nie'"
                  ></span>
                </label>
              </div>
              <div class="mb-2" v-if="addEditObject.field_type == 'select'">
                <label class="form-box__label form-box__label--mt">
                  Opcje
                </label>
                <table class="items-table items-table--shrink">
                  <thead class="items-table__thead">
                    <tr class="items-table__tr">
                      <th class="items-table__th items-table__th--sm">ID</th>
                      <th class="items-table__th">Nazwa</th>
                      <th class="items-table__th p-0 text-right">Akcje</th>
                    </tr>
                  </thead>
                  <tbody class="items-table__tbody">
                    <template v-for="option in addEditObject.options">
                      <tr class="items-table__tr" v-bind:key="option.id">
                        <td class="items-table__td items-table__td--sm">
                          <div class="form-box__mobile-element">Id</div>
                          <div v-if="option.id">
                            {{ option.id }}
                          </div>
                          <div v-else>-</div>
                        </td>

                        <td class="items-table__td">
                          <div class="form-box__mobile-element">Nazwa</div>
                          <div v-if="editingOption.name == option.name">
                            <input
                              class="items-table__input"
                              type="text"
                              v-model="editingOption.name"
                              @keypress.enter.prevent="saveObject()"
                            />
                          </div>
                          <div v-else>
                            {{ option.name }}
                          </div>
                        </td>

                        <td class="items-table__td items-table__td--button">
                          <div class="form-box__mobile-element">Akcje</div>
                          <div class="d-flex justify-content-end">
                            <button
                              v-if="editingOption.name == option.name"
                              class="button-icon"
                              @click.prevent="option.name ? saveOption() : null"
                            >
                              <icon icon="save" />
                            </button>
                            <button
                              v-else
                              class="button-icon"
                              @click.prevent="editOption(option)"
                            >
                              <icon icon="edit" />
                            </button>

                            <button
                              class="button-icon button-icon--ml-10"
                              @click="deleteOption(option)"
                            >
                              <icon icon="trash" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td class="items-table__td items-table__td--sm">
                        <div class="form-box__mobile-element"></div>
                        #
                      </td>
                      <td class="items-table__td">
                        <div class="form-box__mobile-element">Nazwa</div>
                        <input
                          v-model="newOption.name"
                          class="items-table__input"
                        />
                      </td>
                      <td class="items-table__td items-table__td--button">
                        <div class="form-box__mobile-element"></div>
                        <div class="d-flex justify-content-end">
                          <button
                            class="button button--icon"
                            @click="newOption.name ? addOption() : null"
                          >
                            <icon
                              icon="plus"
                              icon-classes="button__icon button__icon--left icon--white icon__plus--small"
                            />
                            <span class="button__text">Dodaj</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--ml-10"
          @click="
            addEditObject.id
              ? updateObject(addEditObject)
              : createObject(addEditObject)
          "
        >
          Zapisz
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import Icon from "./../parts/Icon";
import Modal from "./../parts/Modals/Modal";
import Treeselect from "@riophae/vue-treeselect";
import SpimLoader from "../parts/SpimLoader.vue";
import ContentTop from "../parts/ContentTop.vue";
import AddButton from "../parts/buttons/addButton.vue";

export default {
  data() {
    return {
      isLoading: true,
      objects: {},
      showDeleteModal: false,
      showEditAddModal: false,
      addEditObject: {},
      objectToDelete: {},
      newOption: {},
      editingOption: {},
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
      field_types: [
        {
          id: "string",
          name: "Tekst",
        },
        {
          id: "boolean",
          name: "Prawda-fałsz",
        },
        {
          id: "select",
          name: "Lista",
        },
      ],
    };
  },
  components: {
    Icon,
    Modal,
    Treeselect,
    SpimLoader,
    ContentTop,
    AddButton,
  },
  async created() {
    this.getObjects();
  },
  methods: {
    closeEditAddModal() {
      this.showEditAddModal = !this.showEditAddModal;
      this.addEditObject = {};
    },
    async createObject(data) {
      data.show_in_variants = data.show_in_variants ? true : false;
      data.options = data.options ? data.options : [];
      data.multi_select = data.multi_select ? true : false;

      let vm = this;
      try {
        const response = await axios.post(API_BASE_URL + "/attributes", data);
        vm.objects = response.data;
        this.addEditObject = {};
        this.showEditAddModal = false;
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Poprawnie dodano atrybut.",
        });
      } catch (error) {
        vm.$root.$emit("noticesBox", error.response.data);
      }
    },
    async updateObject(data) {
      if (data.field_type == "boolean" || data.field_type == "string") {
        data.options = [];
        data.multi_select = false;
      }
      data.options = data.options ? data.options : [];

      try {
        const response = await axios.put(
          API_BASE_URL + "/attributes/" + data.id,
          data
        );
        this.objects = response.data;
        this.addEditObject = {};
        this.showEditAddModal = false;
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Poprawnie zedytowano atrybut.",
        });
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async getObjects() {
      this.isLoading = true;
      try {
        const response = await axios.get(API_BASE_URL + "/attributes");
        this.objects = response.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async deleteObject(object) {
      this.showDeleteModal = true;
      this.objectToDelete = object;
    },
    async confirmDeleteObject() {
      this.showDeleteModal = false;
      try {
        await axios.delete(
          API_BASE_URL + "/attributes/" + this.objectToDelete.id
        );
        this.getObjects();
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    editObject(object) {
      this.showEditAddModal = true;
      this.addEditObject = object;
    },
    addObject() {
      this.showEditAddModal = true;
    },
    addOption() {
      this.addEditObject.options = this.addEditObject.options
        ? this.addEditObject.options
        : [];
      this.addEditObject.options.push(this.newOption);
      this.newOption = {};
    },
    deleteOption(option) {
      var index = this.addEditObject.options.indexOf(option);
      var tempObject = { ...this.addEditObject };
      tempObject.options.splice(index, 1);
      this.addEditObject = tempObject;
    },
    editOption(option) {
      this.editingOption = option;
    },
    saveOption() {
      this.editingOption = {};
    },
  },
};
</script>
