<template>
  <div>
    <div class="product-form row">
      <div class="w-100">
        <div class="col-24 product-form__content">
          <div class="row">
            <div class="col-24 col-xl">
              <div id="files-upload">
                <div class="content-top row">
                  <div
                    class="col-24 d-flex justify-content-between align-items-center"
                  >
                    <div class="content-top__title-box">
                      <span class="section-header">Dodaj pliki</span>
                      <div class="content-top__subheading">
                        <template v-if="onGoingUploads > 0"
                          >Ładowanie {{ onGoingUploads }}
                          {{
                            onGoingUploads
                              | numeralsText("file", "files", "files")
                          }}
                          ...</template
                        >
                        <template v-else-if="countNotCompleted() > 0"
                          >{{ countNotCompleted() }}
                          {{
                            countNotCompleted()
                              | numeralsText(
                                "plik gotowy",
                                "pliki gotowe",
                                "plików gotowych"
                              )
                          }}
                          do przesłania</template
                        >
                        <template v-else>Wybierz plik do przesłania</template>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="forms.length > 0">
                  <div class="col-24">
                    <div
                      class="files-upload"
                      :class="{ 'is-dragover': isDragOver }"
                      @dragenter="isDragOver = true"
                      @dragleave="isDragOver = false"
                      @drop.prevent="addFile"
                      @dragover.prevent
                    >
                      <div
                        class="row file-form"
                        :class="{ 'is-completed': form.completed }"
                        v-for="(form, index) in forms"
                        :key="index"
                      >
                        <div
                          class="file-form__data col-24 col-xl-18 d-flex align-items-center pl-0"
                        >
                          <div
                            class="files-upload__icon"
                            v-if="form.icon"
                            :style="{
                              backgroundImage: 'url(' + form.icon + ')',
                            }"
                          ></div>
                          <span class="file-form__name"
                            >{{ form.file.name }} ({{
                              form.file.size | prettyBytes
                            }})</span
                          >
                        </div>
                        <div class="file-form__data col-24 col-xl-8">
                          <input
                            v-if="!form.completed"
                            class="file-form__input"
                            :readonly="form.isUploading"
                            :placeholder="
                              form.isUploading
                                ? ''
                                : 'Kliknij aby dodać nazwe...'
                            "
                            v-model="form.display_name"
                          />
                          <div v-else>{{ form.display_name }}</div>
                        </div>
                        <div class="file-form__data col-24 col-xl-8">
                          <input
                            v-if="!form.completed"
                            class="file-form__input"
                            :readonly="form.isUploading"
                            :placeholder="
                              form.isUploading
                                ? ''
                                : 'Kliknij aby dodać opis...'
                            "
                            v-model="form.description"
                          />
                          <div v-else>{{ form.description }}</div>
                        </div>
                        <div class="file-form__data col-24 col-xl-6">
                          <treeselect
                            v-if="!form.completed"
                            v-model="form.type"
                            value-format="object"
                            :multiple="false"
                            :options="fileTypes"
                            placeholder=""
                            :normalizer="normalizer"
                            :clearable="false"
                          />
                          <div v-else>{{ form.type.name }}</div>
                        </div>
                        <div class="file-form__data col-24 col-xl-2 pr-0">
                          <div class="d-flex justify-content-end">
                            <button
                              class="button-icon"
                              v-if="!form.completed"
                              @click="removeForm(form)"
                            >
                              <icon icon="delete"></icon>
                            </button>
                            <button
                              v-else
                              class="button-icon button-icon--success"
                              @click="removeForm(form)"
                            >
                              <icon icon="check"></icon>
                            </button>
                          </div>
                        </div>
                        <div
                          class="col-24 d-flex file-form__messages pl-0 pr-0"
                          v-if="form.message"
                        >
                          <div
                            class="file-form__message"
                            :class="'is-' + form.status"
                          >
                            {{ form.message }}
                          </div>
                        </div>
                        <div
                          class="col-24 d-flex file-form__messages pl-0 pr-0"
                          v-if="form.errors"
                        >
                          <div
                            class="file-form__message"
                            :class="'is-' + form.status"
                            v-for="(error, index) in form.errors"
                            :key="'error_' + index"
                          >
                            <template v-for="text in error">{{
                              text
                            }}</template>
                          </div>
                        </div>
                        <progress
                          class="file-form__progress"
                          max="100"
                          :value.prop="form.progress"
                          :class="'is-' + form.status"
                        ></progress>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-24 d-flex justify-content-end files-upload__footer"
                  >
                    <button
                      class="button button--upload button--ml-10"
                      :class="{ 'is-completed': uploadCompleted }"
                      :disabled="
                        onGoingUploads > 0 ||
                        forms.length <= 0 ||
                        countNotCompleted() == 0
                      "
                      @click.prevent="upload()"
                    >
                      <div
                        class="loader button__icon button__icon--left"
                        v-if="onGoingUploads > 0"
                      ></div>
                      <span
                        v-text="onGoingUploads > 0 ? 'Zapisywanie' : 'Zapisz'"
                      ></span>
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-24">
                    <div
                      v-cloak
                      class="files-drop-area"
                      :class="{ 'is-dragover': isDragOver }"
                      @dragenter="isDragOver = true"
                      @dragleave="isDragOver = false"
                      @drop.prevent="addFile"
                      @dragover.prevent
                    >
                      <div class="files-drop-area__wrap">
                        <template v-if="isDragOver">
                          <span class="files-drop-area__msg"
                            >Przeciągnij i upuść pliki tutaj, aby je
                            przesłać</span
                          >
                        </template>
                        <template v-else>
                          <span class="files-drop-area__msg"
                            >Przeciągnij i upuść tutaj <br />lub</span
                          >&nbsp;<span
                            class="files-drop-area__button"
                            @click="$refs.fileUpload.click()"
                            >Prześlij z komputera</span
                          >
                        </template>
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  class="files-upload__add-files-input"
                  ref="fileUpload"
                  type="file"
                  @change="addFile"
                  multiple="true"
                />
              </div>
              <div class="files-list mt-4" v-if="files">
                <div class="content-top row">
                  <div class="col-24 content-top__wrap">
                    <div class="content-top__title-box">
                      <span class="section-header">Lista plików</span>
                      <div class="content-top__subheading">
                        {{ files.total }}
                        {{
                          files.total | numeralsText("plik", "pliki", "plików")
                        }}
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="files.data && files.data.length > 0"
                    class="col-24 d-flex content-top__icons-wrap"
                  >
                    <button
                      class="button-icon button-icon--medium button-icon--red mr-2"
                      @click.prevent="massDelete()"
                      :disabled="selectedFiles < 1"
                    >
                      <icon icon="trash" icon-classes="icon--regular" />
                    </button>

                    <button
                      class="button-icon button-icon--medium button-icon--red button-icon--transform"
                      @click.prevent="massDownloadFile()"
                      :disabled="selectedFiles < 1"
                    >
                      <icon icon="export" icon-classes="icon--regular" />
                    </button>
                  </div>
                </div>
                <div
                  class="spim-loader-container"
                  :class="{ 'is-loading': isLoading }"
                >
                  <spim-loader></spim-loader>

                  <table
                    class="items-table"
                    v-if="files.data && files.data.length > 0"
                  >
                    <thead class="items-table__thead">
                      <tr class="items-table__tr">
                        <th class="items-table__th" style="width: 70px;">
                          <label for="check-product-toggle" class="checkbox">
                            <input
                              id="check-product-toggle"
                              class="checkbox__input"
                              type="checkbox"
                              v-model="toggleSelectFiles"
                            />
                            <span class="checkbox__checkmark"></span>
                          </label>
                        </th>
                        <th class="items-table__th" style="width: 70px;">
                          #
                        </th>
                        <th class="items-table__th" style="width: 400px;"></th>
                        <th class="items-table__th" style="min-width: 250px;">
                          Nazwa
                        </th>
                        <th class="items-table__th" style="min-width: 250px;">
                          Opis
                        </th>
                        <th class="items-table__th" style="min-width: 50px;">
                          Typ pliku
                        </th>
                        <th class="items-table__th pr-0 text-right">Akcje</th>
                      </tr>
                    </thead>

                    <tbody class="items-table__tbody">
                      <template v-for="file in files.data">
                        <tr class="items-table__tr" v-bind:key="file.id">
                          <td
                            class="items-table__td items-table__td--not-text d-none d-xl-table-cell"
                          >
                            <label
                              :for="'check-file-' + file.id"
                              class="checkbox"
                            >
                              <input
                                :id="'check-file-' + file.id"
                                class="checkbox__input"
                                type="checkbox"
                                :value="file.id"
                                v-model="selectedFiles"
                                @keypress.enter.prevent="saveObject()"
                              />
                              <span class="checkbox__checkmark"></span>
                            </label>
                          </td>

                          <td class="items-table__td">
                            <div class="form-box__mobile-element">#</div>
                            <div class="form-box__content">
                              {{ file.id }}
                            </div>
                          </td>
                          <td class="items-table__td">
                            <div class="form-box__content">
                              <img
                                v-if="imageExtensions.includes(file.type.ext)"
                                :src="file.path"
                                class="form-box__img form-box__img--crop"
                              />
                              <div v-else class="items-table__icon">
                                <img
                                  src="../../iconfont/default-file-icon.svg"
                                />
                              </div>
                            </div>
                          </td>

                          <td class="items-table__td">
                            <div class="form-box__mobile-element">
                              Nazwa
                            </div>
                            <div class="form-box__content">
                              {{ file.display_name }}
                            </div>
                          </td>
                          <td class="items-table__td">
                            <div class="form-box__mobile-element">
                              Opis
                            </div>
                            <div class="form-box__content">
                              {{ file.description }}
                            </div>
                          </td>
                          <td class="items-table__td">
                            <div class="form-box__mobile-element">
                              Typ pliku
                            </div>
                            <div class="form-box__content">
                              {{ file.type.ext }}
                            </div>
                          </td>
                          <td class="items-table__td pr-0">
                            <div class="form-box__buttons">
                              <button
                                class="button-icon button-icon--ml-10 button-icon--transform"
                                @click="downloadFile(file)"
                              >
                                <icon
                                  icon="export"
                                  icon-classes="icon--regular"
                                />
                              </button>
                              <button
                                class="button-icon button-icon--ml-10 button-icon--transform"
                                @click="editFile(file)"
                              >
                                <icon
                                  icon="edit"
                                  icon-classes="icon--regular"
                                />
                              </button>

                              <button
                                class="button-icon button-icon--ml-10"
                                @click="deleteFile(file)"
                              >
                                <icon
                                  icon="trash"
                                  icon-classes="icon--regular"
                                />
                              </button>
                            </div>
                            <div class="form-box__mobile-element">
                              <label
                                :for="'check-file-' + file.id"
                                class="checkbox"
                              >
                                <input
                                  :id="'check-file-' + file.id"
                                  class="checkbox__input"
                                  type="checkbox"
                                  :value="file.id"
                                  v-model="selectedFiles"
                                />
                                <span class="checkbox__checkmark"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>

                  <div v-else class="content">
                    Brak wyników.
                  </div>

                  <pagination
                    :pagination="files"
                    @paginate="getFiles()"
                    :offset="1"
                  ></pagination>
                </div>

                <!-- Modal edit file -->
                <modal
                  :show="showEditModal"
                  size="lg"
                  @close="showEditModal = !showEditModal"
                >
                  <div slot="header" class="notice-modal__header--title">
                    Edycja pliku
                  </div>

                  <div slot="body">
                    <div class="row">
                      <div class="col-24 mb-4">
                        <div class="product-form__title">Nazwa</div>
                        <input
                          class="product-form__input"
                          v-model="editingObject.display_name"
                        />
                      </div>

                      <div class="col-24 mt-4">
                        <div class="product-form__title">Opis</div>
                        <textarea
                          class="product-form__input product-form__input--textarea"
                          v-model="editingObject.description"
                        ></textarea>
                      </div>
                      <div class="col-24 mt-4">
                        <div class="product-form__title">Typ pliku</div>
                        <treeselect
                          v-model="editingObject.type"
                          value-format="object"
                          :multiple="false"
                          :options="fileTypes"
                          placeholder=""
                          :normalizer="normalizer"
                          :clearable="false"
                        />
                      </div>
                    </div>
                  </div>

                  <div slot="footer" class="d-flex align-items-center">
                    <button
                      class="button button--small button--grey"
                      @click="showEditModal = false"
                      :disabled="isStoring"
                    >
                      Anuluj
                    </button>
                    <button
                      class="button button--small button--ml-10"
                      @click="saveObject()"
                      :disabled="isStoring"
                      v-text="isStoring ? 'Zapisywanie...' : 'Zapisz'"
                    >
                      Zapisz
                    </button>
                  </div>
                </modal>

                <!-- Modal mass delete files -->
                <modal
                  :show="showDeleteModal"
                  size="md"
                  @close="showDeleteModal = !showDeleteModal"
                >
                  <div slot="header" class="notice-modal__header--title">
                    Usuwanie pliku
                  </div>

                  <div slot="body">
                    <div>
                      Czy na pewno chcesz usunąć plik
                      <strong>{{ objectToDelete.name }}</strong
                      >?
                    </div>
                  </div>

                  <div slot="footer" class="d-flex align-items-center">
                    <button
                      class="button button--small button--grey"
                      @click="showDeleteModal = false"
                    >
                      Nie
                    </button>
                    <button
                      class="button button--small button--ml-10"
                      @click="confirmDeleteFile()"
                    >
                      Tak
                    </button>
                  </div>
                </modal>

                <!-- Modal mass delete files -->
                <modal
                  :show="showMassDeleteModal"
                  size="md"
                  @close="showMassDeleteModal = !showMassDeleteModal"
                >
                  <div slot="header" class="notice-modal__header--title">
                    Usuwanie plików
                  </div>

                  <div slot="body">
                    <div>
                      Czy na pewno chcesz usunąć
                      {{
                        selectedFiles.length
                          | numeralsText(
                            "zaznaczony",
                            "zaznaczone",
                            "zaznaczone"
                          )
                      }}
                      <strong>{{ selectedFiles.length }}</strong>
                      {{
                        selectedFiles.length
                          | numeralsText("plik", "pliki", "plików")
                      }}
                      ?
                    </div>
                  </div>

                  <div slot="footer" class="d-flex align-items-center">
                    <button
                      class="button button--small button--grey"
                      @click="showMassDeleteModal = false"
                    >
                      No
                    </button>
                    <button
                      class="button button--small button--ml-10"
                      @click="confirmMassDelete()"
                    >
                      Yes
                    </button>
                  </div>
                </modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

import Icon from "./../parts/Icon";
import Pagination from "./../parts/Pagination";
import Modal from "./../parts/Modals/Modal";
import Treeselect from "@riophae/vue-treeselect";
import SpimLoader from "../parts/SpimLoader.vue";

export default {
  data() {
    return {
      fileTypes: [],
      isLoading: true,
      isStoring: false,
      forms: [],
      isDragOver: false,
      disableUpload: true,
      onGoingUploads: false,
      uploadCompleted: false,
      showEditModal: false,
      editingObject: {},
      showMassDeleteModal: false,
      showDeleteModal: false,
      selectedFiles: [],
      files: null,
      message: "",
      search: "",
      order: "desc",
      orderBy: "created_at",
      objectToDelete: {},
      imageExtensions: ["png", "jpg", "jpeg", "gif"],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  components: {
    Icon,
    Pagination,
    Modal,
    Treeselect,
    SpimLoader,
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
  },
  watch: {
    onGoingUploads: {
      handler(newVal) {
        if (newVal == 0) {
          this.uploadCompleted = true;
          this.disableUpload = false;
        }
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.getFiles();
    this.getFileTypes();
  },
  computed: {
    toggleSelectFiles: {
      get: function () {
        return this.files.data
          ? this.selectedFiles.length == this.files.data.length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.files.data.forEach(function (file) {
            selected.push(file.id);
          });
        }

        this.selectedFiles = selected;
      },
    },
  },
  methods: {
    async getFileTypes() {
      this.response = {};
      try {
        const response = await axios.get(API_BASE_URL + "/file-types");
        this.fileTypes = response.data;
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    addFile(e) {
      let vm = this;
      vm.disableUpload = false;
      var selectedFiles = e.target.files || e.dataTransfer.files;
      if (!selectedFiles) return;
      vm.isDragOver = false;

      [...selectedFiles].forEach((f) => {
        let preparedForm = {
          name: f.name,
          completed: false,
          display_name: "",
          type: this.getType(f.name),
          description: "",
          message: "",
          file: f,
          progress: 0,
          status: "default",
          isUploading: false,
          errors: {},
        };
        vm.forms.push(preparedForm);
      });

      vm.$refs.fileUpload.value = "";
    },
    removeForm(form) {
      this.forms = this.forms.filter((f) => {
        return f != form;
      });
    },
    async upload() {
      let vm = this;
      vm.disableUpload = true;
      vm.onGoingUploads = this.countNotCompleted();

      vm.forms.forEach((form) => {
        let thisForm = form;
        if (form.completed) return;

        thisForm.errors = {};
        thisForm.status = "default";

        let formData = new FormData();
        formData.append("file", form.file);
        formData.append("product_id", this.product.id);
        formData.append("description", form.description);
        formData.append("display_name", form.display_name);
        formData.append("type_id", form.type.id);
        thisForm.isUploading = true;

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            form.progress = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(vm),
        };

        axios
          .post(API_BASE_URL + "/productfiles/upload", formData, config)
          .then((response) => {
            thisForm.completed = true;
            vm.onGoingUploads--;
            thisForm.isUploading = false;
            // thisForm.message = response.data.message;
            thisForm.status = "success";
            this.files = response.data;
            this.isLoading = false;
            this.$root.$emit("productIsChanged");
          })
          .catch(function (error) {
            vm.onGoingUploads--;
            thisForm.isUploading = false;
            if (error.response.data.errors) {
              thisForm.errors = error.response.data.errors;
            }
            if (error.response.data.errors) {
              thisForm.message = error.response.data.message;
            }
            thisForm.status = "error";
          });

        // this.getFiles();
      });
    },
    countNotCompleted() {
      let count = 0;
      this.forms.forEach(function (form) {
        if (!form.completed) count++;
      });

      return count;
    },
    async getFiles() {
      this.selectedFiles = [];
      this.isLoading = true;
      try {
        var params = {
          product_id: this.$route.params.id,
          //   page: this.files.current_page,
          order_by: this.orderBy,
          per_page: 51,
          order: this.order,
        };

        if (this.search != "") params.search = this.search;

        const response = await axios.get(API_BASE_URL + "/productfiles", {
          params: params,
        });
        this.files = response.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    deleteFile(file) {
      this.showDeleteModal = true;
      this.objectToDelete = file;
    },
    async confirmDeleteFile() {
      this.showDeleteModal = false;
      try {
        await axios.delete(
          API_BASE_URL + "/productfiles/" + this.objectToDelete.id
        );
        this.getFiles();
        this.$root.$emit("productIsChanged");
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async downloadFile(file) {
      try {
        await axios
          .get(API_BASE_URL + "/productfiles/download/" + file.id, {
            responseType: "blob",
          })
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              file.display_name + "." + file.type.ext
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          });

        // this.getFiles();
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async massDownloadFile() {
      try {
        await axios
          .post(
            API_BASE_URL + "/productfiles/download",
            { ids: this.selectedFiles, product_id: this.product.id },
            { responseType: "blob" }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "product_" + this.product.id + "_files.zip"
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          });

        // this.getFiles();
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    sortObjects() {
      event.target.setAttribute("order", this.order == "asc" ? "desc" : "asc");
      this.orderBy = event.target.getAttribute("order-by");
      this.order = event.target.getAttribute("order");
      this.getFiles();
    },
    async massDelete() {
      this.showMassDeleteModal = true;
    },
    async confirmMassDelete() {
      try {
        await axios.post(API_BASE_URL + "/productfiles/delete", {
          files: this.selectedFiles,
        });
        this.getFiles();
        this.showMassDeleteModal = false;
        this.$root.$emit("productIsChanged");
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
        this.getFiles();
      }
    },
    editFile(object) {
      this.editingObject = { ...object };
      this.showEditModal = true;
    },
    async saveObject() {
      try {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        let formData = new FormData();

        let data = this.editingObject;
        if (data.id) {
          formData.append("id", data.id);
        }
        if (data.display_name) {
          formData.append("display_name", data.display_name);
        }
        if (data.description) {
          formData.append("description", data.description);
        }
        if (data.type) {
          formData.append("type_id", data.type.id);
        }

        let response = await axios.post(
          API_BASE_URL + "/files/update",
          formData,
          config
        );

        this.editingObject = {};
        this.getFiles();
        this.$root.$emit("productIsChanged");

        this.$root.$emit("noticesBox", {
          type: "success",
          message: response.data.message,
        });

        this.showEditModal = false;
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    getType(link) {
      let extension = link.substring(link.lastIndexOf(".") + 1);
      return this.fileTypes.find((fileType) => {
        return fileType.ext == extension.toLowerCase();
      });
    },
  },
};
</script>
