import Vue from "vue";
import Router from "vue-router";
import { i18n } from "./i18n";
import store from "./store/index";

// Components
import Dashboard from "./components/Dashboard.vue";
import ActivityLog from "./components/ActivityLog.vue";
import Login from "./components/Login.vue";
import ResetPassword from "./components/ResetPassword.vue";

// -- Users
import UsersList from "./components/users/UsersList.vue";
import UsersStore from "./components/users/UsersStore.vue";

// -- Account
import AccountEdit from "./components/AccountEdit.vue";

// -- Products
import ProductsList from "./components/ProductsList.vue";
import ProductEdit from "./components/ProductEdit.vue";

// -- Dicionaries
import DictionariesList from "./components/dictionaries/DictionariesList.vue";
import DictionariesLanguages from "./components/dictionaries/Languages.vue";
import DictionariesFileTypes from "./components/dictionaries/FileTypes.vue";
import DictionariesLines from "./components/dictionaries/Lines.vue";
import DictionariesMarks from "./components/dictionaries/Marks.vue";

import DictionariesCategories from "./components/dictionaries/Categories.vue";
import DictionariesWarranties from "./components/dictionaries/Warranties.vue";
import DictionariesSpimCategories from "./components/dictionaries/SpimCategories.vue";
import DictionariesAttributes from "./components/dictionaries/Attributes.vue";
import DictionariesCollections from "./components/dictionaries/Collections.vue";
import DictionariesSeries from "./components/dictionaries/Series.vue";
import DictionariesOrderTimes from "./components/dictionaries/OrderTimes.vue";
import DictionariesMarkets from "./components/dictionaries/Markets.vue";

// -- Files
import Files from "./components/Files.vue";
import FilesUpload from "./components/FilesUpload.vue";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: Dashboard,
      meta: {
        submenu: "dashboard",
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: ResetPassword,
    },
    {
      path: "/activity-log",
      name: "activityLog",
      component: ActivityLog,
      meta: {
        submenu: "activity",
      },
    },
    {
      path: "/users-list",
      name: "usersList",
      component: UsersList,
      meta: {
        submenu: "users",
        adminAuth: true,
      },
    },
    {
      path: "/users-store",
      name: "usersStore",
      component: UsersStore,
      meta: {
        submenu: "users",
        adminAuth: true,
      },
    },
    {
      path: "/edit-account",
      name: "accountEdit",
      component: AccountEdit,
      meta: {
        submenu: "users",
        adminAuth: true,
      },
    },
    {
      path: "/products",
      name: "productsList",
      component: ProductsList,
      meta: {
        submenu: "products",
      },
    },
    {
      path: "/product/:id/:locale/edit",
      name: "productEdit",
      component: ProductEdit,
      meta: {
        submenu: "products",
      },
    },
    {
      path: "/dictionaries",
      name: "dictionariesList",
      component: DictionariesList,
      meta: {
        submenu: "dictionaries",
      },
      children: [
        {
          path: "languages",
          name: "dictionariesLanguages",
          component: DictionariesLanguages,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "file-types",
          name: "dictionariesFileTypes",
          component: DictionariesFileTypes,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "lines",
          name: "dictionariesLines",
          component: DictionariesLines,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "marks",
          name: "dictionariesMarks",
          component: DictionariesMarks,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "categories",
          name: "dictionariesCategories",
          component: DictionariesCategories,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "warranties",
          name: "dictionariesWarranties",
          component: DictionariesWarranties,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "attributes",
          name: "dictionariesAttributes",
          component: DictionariesAttributes,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "collections",
          name: "dictionariesCollections",
          component: DictionariesCollections,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "series",
          name: "dictionariesSeries",
          component: DictionariesSeries,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "order-times",
          name: "dictionariesOrderTimes",
          component: DictionariesOrderTimes,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "spim-categories",
          name: "dictionariesSpimCategories",
          component: DictionariesSpimCategories,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "markets",
          name: "dictionariesMarkets",
          component: DictionariesMarkets,
          meta: {
            submenu: "dictionaries",
          },
        },
      ],
    },
    {
      path: "/files",
      name: "files",
      component: Files,
      meta: {
        submenu: "files",
      },
    },
    {
      path: "/files/upload",
      name: "filesUpload",
      component: FilesUpload,
      meta: {
        submenu: "files",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // try to access a restricted page + not logged in
  const publicPages = ["/login", "/reset-password"];
  const authRequired = !publicPages.includes(to.path);
  const user = localStorage.getItem("user");
  if (authRequired && !user) {
    return next("/login");
  } else if (to.meta.adminAuth) {
    let userJson = JSON.parse(user);
    if (userJson.role != "admin") {
      setTimeout(() => next("/"), 800);
    }
  }

  // language
  if (
    store.state.language.language &&
    store.state.language.language !== i18n.locale
  ) {
    i18n.locale = store.state.language.language;
    next();
  } else if (!store.state.language.language) {
    store.dispatch("language/setLanguage", { code: "pl" }).then(() => {
      i18n.locale = store.state.language.language;
      next();
    });
  } else {
    next();
  }

  next();
});
