<template>
  <div>
    <dictionary-modal-table
      dictionary_link="/marks"
      title="Marki produktów"
      :numeral_text="['marka produktów', 'marki produktów', 'marek produktów']"
      :columns_title="['name']"
    ></dictionary-modal-table>
  </div>
</template>

<script>
import DictionaryModalTable from "./DictionaryModalTable.vue";
export default {
  components: {
    DictionaryModalTable,
  },
};
</script>
