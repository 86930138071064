<template>
  <div>
    <dictionary-modal-table
      dictionary_link="/collections"
      title="Lista kolekcji"
      :numeral_text="['Kolekcja', 'Kolekcje', 'Kolekcji']"
      :columns_title="['name']"
    ></dictionary-modal-table>
  </div>
</template>

<script>
import DictionaryModalTable from "./DictionaryModalTable.vue";
export default {
  components: {
    DictionaryModalTable,
  },
};
</script>
