<template>
  <div class="simple-search align-items-center d-flex justify-content-xl-end">
    <div class="product-form__input-wrap mb-0 mr-2">
      <div class="d-flex align-items-center">
        <datepicker
          :input-class="'product-form__input product-form__input--higher'"
          :typeable="true"
          :format="customFormatter"
          v-model="date_from"
          placeholder="Data od"
        ></datepicker>
      </div>
    </div>
    <div class="product-form__input-wrap mb-0 mr-2">
      <div class="d-flex align-items-center">
        <datepicker
          :input-class="'product-form__input product-form__input--higher'"
          :typeable="true"
          :format="customFormatter"
          v-model="date_to"
          placeholder="Data do"
        ></datepicker>
      </div>
    </div>
    <button
      class="button button--icon  ml-1"
      @click="$emit('getObjects', date_from, date_to)"
    >
      <icon
        icon="search"
        icon-classes="button__icon button__icon--left icon--white"
      />
      <span class="button__text">Szukaj</span>
    </button>
  </div>
</template>
<script>
import Icon from "../Icon.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  data() {
    return {
      search: "",
      date_from: null,
      date_to: null,
    };
  },
  components: {
    Icon,
    Datepicker,
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
