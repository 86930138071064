<template>
  <div class="alert-data">
    <icon
      v-if="missingAlert.product.length || missingAlert.variants.length"
      icon="warning"
    />

    <div
      v-if="missingAlert.product.length || missingAlert.variants.length"
      class="alert-data__box"
    >
      <div v-if="missingAlert.product.length != 0">
        <div class="alert-data__title">Wariant:</div>
        <ul
          class="alert-data__list"
          v-for="(productAlert, index) in missingAlert.product"
          :key="index"
        >
          <li>{{ productAlert }}</li>
        </ul>
      </div>
      <div v-if="missingAlert.variants.length">
        <div class="alert-data__title">Produkty:</div>
        <div
          v-for="(variantsAlert, index) in missingAlert.variants"
          :key="index"
        >
          <div class="alert-data__title">
            Wariant id#{{ variantsAlert.id }}:
          </div>
          <ul
            class="alert-data__list"
            v-for="(message, index) in variantsAlert.messages"
            :key="index"
          >
            <li>{{ message }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "./Icon";

export default {
  data() {
    return {
      missingAlert: {
        product: [],
        variants: [],
      },
    };
  },
  components: {
    Icon,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.getMissingData();
  },
  methods: {
    getMissingData() {
      //produkt nie mający wariantów
      if (!this.product.have_variants) {
        //brak symbol
        if (this.product.symbol == null) {
          this.missingAlert.product.push("Brak symbolu wariantu.");
        }

        //brak ean
        if (this.product.ean13 == null) {
          this.missingAlert.product.push("Brak kodu EAN.");
        }

        //brak nazwy
        if (this.product.name == null) {
          this.missingAlert.product.push("Brak nazwy.");
        }
        //brak zdjęcia głównego
        if (
          this.product.main_photo == null ||
          this.product.main_photo.id == null
        ) {
          this.missingAlert.product.push("Brak zdjęcia.");
        }

        //brak kategorii
        if (this.product.category_id == null) {
          this.missingAlert.product.push("Brak kategorii.");
        }

        //brak kategorii spim
        if (this.product.spim_category_id == null) {
          this.missingAlert.product.push("brak kategorii spim.");
        }
      } else {
        this.product.variants.forEach((variant) => {
          // gdy brak symbol
          let variantMessage = [];
          if (variant.symbol) {
            variantMessage.push("Brak symbolu produktu.");
          }

          //gdy brak ean
          if (variant.ean13 == null) {
            variantMessage.push("Brak kodu EAN.");
          }

          //gdy brak nazwy
          if (variant.name == null) {
            variantMessage.push("Brak nazwy.");
          }
          if (variantMessage.length) {
            this.missingAlert.variants.push({
              id: variant.id,
              messages: variantMessage,
            });
          }
        });
      }
    },
  },
};
</script>
