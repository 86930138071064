<template>
  <div
    class="header d-flex justify-content-between align-items-center"
    :class="{ 'is-opened': isOpened }"
  >
    <div class="header__wrap">
      <div class="header__content">
        <div class="header__left">
          <h1 class="header__title">{{ title }}</h1>
          <div class="header__subheading" v-if="subheading">
            {{ subheading }}
          </div>
        </div>
        <div class="header__language-switcher">
          <language-switcher
            :key="$route.params.id"
            v-if="
              !hideLanguageSwitcher.includes($route.name) &&
              !hideLanguageSwitcher.includes($route.meta.submenu)
            "
          ></language-switcher>
          <img class="header__logo" src="../../img/new_trendy_logo.png" />
        </div>
      </div>
      <div class="header__content">
        <div class="header__subheading-second" v-if="subheading_second">
          {{ subheading_second }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/parts/LanguageSwitcher";

export default {
  data() {
    return {
      hideLanguageSwitcher: [
        "activity",
        "files",
        "productEdit",
        "dictionariesLanguages",
      ],
      isOpened: false,
    };
  },
  props: {
    title: {
      type: String,
      require: true,
    },
    subheading: {
      type: String,
      require: false,
    },
    subheading_second: {
      type: String,
      require: false,
    },
  },
  components: {
    LanguageSwitcher,
  },
};
</script>
