<template>
  <div class="product-form__input-wrap">
    <div class="product-form__title">{{ label }}</div>
    <treeselect
      class="vue-treeselect"
      v-if="options.length > 0"
      v-model="object[fieldName]"
      :multiple="multiple"
      :options="options"
      placeholder=""
      :normalizer="normalizer"
      :clearable="clearable"
      :disable-branch-nodes="disableBranchNodes"
      :value-consists-of="valueConsistsOf"
      :disabled="disabled"
      @input="
        $root.$emit('productIsChanged'),
          $emit('additionalFunction', object),
          checkProperty()
      "
    />
    <div
      v-else
      class="product-form__note"
      v-text="
        isLoading
          ? 'Ładowanie...'
          : 'Nie dodano jeszcze tego elementu do słowników.'
      "
    ></div>
  </div>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect";

export default {
  data() {
    return {
      normalizer(node) {
        let data = {
          id: node.id,
          label: node.name,
        };
        if (node.subcategories && node.subcategories.length > 0) {
          data["children"] = node.subcategories;
        }
        return data;
      },
    };
  },
  components: {
    Treeselect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    object: {
      type: Object,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
    },
    clearable: {
      type: Boolean,
      require: false,
    },
    valueConsistsOf: {
      type: String,
      required: false,
    },
    disableBranchNodes: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},

  methods: {
    checkProperty() {
      if (this.object[this.fieldName] == undefined) {
        this.object[this.fieldName] = null;
      }
    },
  },
};
</script>
