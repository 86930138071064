<template>
  <div>
    <dictionary-modal-table
      dictionary_link="/file-types"
      title="Lista typów plików"
      :numeral_text="['typ pliku', 'typy plików', 'typów plików']"
      :columns_title="['name', 'icon', 'ext']"
    ></dictionary-modal-table>
  </div>
</template>

<script>
import DictionaryModalTable from "./DictionaryModalTable.vue";
export default {
  components: {
    DictionaryModalTable,
  },
};
</script>
