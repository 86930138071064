<template>
  <div>
    <el-tiptap
      v-model="object[fieldName]"
      :extensions="hasExtensions ? extensions : extensionsEmpty"
      :readonly="readonly"
      :enableCharCount="false"
      @onBlur="onInputChange"
      @onUpdate="
        onInputUpdate();
        changeVaule();
      "
    />
  </div>
</template>

<script>
import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  CodeView,
} from "element-tiptap";
import codemirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/xml/xml.js";
import "codemirror/addon/selection/active-line.js";
import "codemirror/addon/edit/closetag.js";
export default {
  data() {
    return {
      textUpdated: false,
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true, menubar: false }),
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new CodeView({
          codemirror,
          codemirrorOptions: {
            styleActiveLine: true,
            autoCloseTags: true,
          },
        }),
      ],
      extensionsEmpty: [new Doc(), new Text(), new Paragraph()],
    };
  },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    object: {
      type: Object,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasExtensions: {
      type: Boolean,
      default: true,
    },
    productChange: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onInputUpdate() {
      this.textUpdated = true;
    },
    onInputChange() {
      if (this.textUpdated && this.productChange) {
        this.$root.$emit("productIsChanged");
      }
    },
    changeVaule() {
      this.$emit("changes", this.fieldName);
    },
  },
};
</script>
