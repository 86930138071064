<template>
  <div class="row">
    <div class="col-24">
      <div class="product-form__title">{{ labelName }}</div>
      <input
        type="file"
        ref="photosInput"
        v-on:input="onImageChange($event), $root.$emit('productIsChanged')"
        style="display: none"
      />

      <button
        class="button button--icon mb-3"
        @click="$refs.photosInput.click()"
        v-if="multiplePhotos || (!multiplePhotos && !photos[0].id)"
      >
        <icon
          icon="plus"
          icon-classes="icon--white button__icon button__icon--left"
        >
        </icon>
        <span class="button__text">Dodaj zdjęcie</span>
      </button>
    </div>
    <div
      :class="{
        'col-8 mb-4': type == 'gallery',
        'col-24 mb-4': type != 'gallery',
      }"
      v-for="photo in photos"
      :key="photo.id"
    >
      <div class="product-photo mb-0" v-if="photo && photo.path">
        <img :src="photo.path" class="product-photo__img" />
        <div
          class="product-photo__actions d-flex align-items-center justify-content-end"
        >
          <button
            class="button-icon button-icon--ml-10 button-icon--bg-white"
            @click="
              $emit('delete', photo, itemIndex, type),
                $root.$emit('productIsChanged')
            "
          >
            <icon icon="trash" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "../parts/Icon";
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      image: "",
    };
  },
  props: {
    photos: {
      type: Array,
      required: false,
    },
    multiplePhotos: {
      type: Boolean,
      required: false,
    },
    itemIndex: {
      type: Number,
      required: false,
    },
    folderPath: {
      type: String,
      required: false,
    },
    labelName: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
  components: {
    Icon,
  },
  mounted() {
    this.$root.$on("saveProduct", () => {
      this.storeProduct();
    });
  },
  beforeDestroy() {
    this.$root.$off("saveProduct");
  },
  methods: {
    onImageChange(event) {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let vm = this;

      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      formData.append("product_id", this.$route.params.id);
      formData.append("folder_path", this.folderPath);

      axios
        .post(API_BASE_URL + "/photos", formData, config)
        .then(function(response) {
          if (vm.multiplePhotos == true) {
            vm.photos.push({
              id: response.data.id,
              path: response.data.path,
              type: vm.type,
            });
          } else {
            vm.photos[0] = {
              id: response.data.id,
              path: response.data.path,
              type: vm.type,
            };
            vm.$emit("add", vm.photos[0], vm.itemIndex, vm.type);
          }
        })
        .catch(function() {
          // vm.$root.$emit("noticesBox", error.response.data);
        });

      this.$refs.photosInput.value = "";
    },
    getApiPath(path) {
      if (path) {
        let timestamp = new Date().getTime();
        return API_BASE_URL + "/" + path + "?" + timestamp;
      } else return require("../../img/default.png");
    },
  },
};
</script>
