<template>
  <div>
    <content-top
      v-show="objects.length"
      title="Lista kategorii SPIM"
      :subheading="`${objects.length} 
          ${$options.filters.numeralsText(
            objects.length,
            'kategoria SPIM',
            'kategorie SPIM',
            'kategorii SPIM'
          )}`"
    ></content-top>
    <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
      <spim-loader></spim-loader>
      <add-button
        @addObject="addObject"
        textButton="Dodaj"
        :icon="true"
      ></add-button>
      <div class="content col-24">
        <table class="items-table items-table--shrink">
          <thead class="items-table__thead">
            <tr class="items-table__tr">
              <th class="items-table__th items-table__th--sm">ID</th>
              <th class="items-table__th">Nazwa</th>
              <th class="items-table__th items-table__th--sm">
                Liczba przypisanych produktów/wariantów
              </th>
              <th class="items-table__th p-0 text-right">Akcje</th>
            </tr>
          </thead>

          <tbody class="items-table__tbody">
            <template v-for="object in objects">
              <tr class="items-table__tr" v-bind:key="object.id">
                <td class="items-table__td items-table__td--sm">
                  <div class="form-box__mobile-element">Id</div>
                  {{ object.id }}
                </td>

                <td class="items-table__td">
                  <div class="form-box__mobile-element">Nazwa</div>
                  <div>
                    {{ object.name }}
                  </div>
                </td>
                <td class="items-table__td">
                  <div class="form-box__mobile-element">
                    Liczba przypisanych produktów/wariantów
                  </div>
                  <div>
                    {{ object.products }}
                  </div>
                </td>
                <td class="items-table__td items-table__td--button">
                  <div class="form-box__mobile-element">Akcje</div>
                  <div class="d-flex justify-content-end">
                    <button
                      class="button-icon"
                      @click.prevent="editObject(object)"
                    >
                      <icon icon="edit" />
                    </button>

                    <button
                      class="button-icon button-icon--ml-10"
                      @click="deleteObject(object)"
                    >
                      <icon icon="trash" />
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal delete -->
    <modal
      :show="showDeleteModal"
      size="md"
      @close="showDeleteModal = !showDeleteModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Usuwanie elementu
      </div>

      <div slot="body">
        <div>
          Czy napewno chcesz usunąć ten element
          <strong>{{
            "#" + objectToDelete.id + " - " + objectToDelete.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showDeleteModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDeleteObject()"
        >
          Tak
        </button>
      </div>
    </modal>

    <!-- Modal edit -->
    <modal
      :show="showEditModal"
      size="lg"
      @close="showEditModal = !showEditModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Edycja elementu
      </div>

      <div slot="body">
        <div class="row">
          <div class="col-24">
            <div class="form-box">
              <label class="form-box__label form-box__label--mt">
                Nazwa:
              </label>
              <input v-model="editingObject.name" class="form-box__input" />
              <label class="form-box__label form-box__label--mt">
                Atrybuty:
              </label>
              <treeselect
                class="vue-treeselect"
                v-if="attributes.length > 0"
                v-model="editingObject.attributes"
                :multiple="true"
                :options="attributes"
                :normalizer="normalizer"
                :clearable="false"
              />
              <div
                v-else
                class="product-form__note"
                v-text="
                  isLoading
                    ? 'Ładowanie...'
                    : 'Nie dodano jeszcze tego elementu do słowników.'
                "
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--ml-10"
          @click="updateObject(editingObject)"
        >
          Zapisz
        </button>
      </div>
    </modal>

    <!-- Modal add -->
    <modal :show="showAddModal" size="lg" @close="showAddModal = !showAddModal">
      <div slot="header" class="notice-modal__header--title">
        Dodawanie elementu
      </div>

      <div slot="body">
        <div class="row">
          <div class="col-24">
            <div class="form-box">
              <label class="form-box__label form-box__label--mt">
                Nazwa:
              </label>
              <input v-model="addingObject.name" class="form-box__input" />
              <label class="form-box__label form-box__label--mt">
                Atrybuty:
              </label>
              <treeselect
                class="vue-treeselect"
                v-if="attributes.length > 0"
                v-model="addingObject.attributes"
                :multiple="true"
                :options="attributes"
                :normalizer="normalizer"
                :clearable="false"
              />
              <div
                v-else
                class="product-form__note"
                v-text="
                  isLoading
                    ? 'Ładowanie...'
                    : 'Nie dodano jeszcze tego elementu do słowników.'
                "
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--ml-10"
          @click="createObject(addingObject)"
        >
          Zapisz
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import Icon from "./../parts/Icon";
import Modal from "./../parts/Modals/Modal";
import Treeselect from "@riophae/vue-treeselect";
import { changeObjectToArray } from "../../helpers/objectToArray";
import SpimLoader from "../parts/SpimLoader.vue";
import ContentTop from "../parts/ContentTop.vue";
import AddButton from "../parts/buttons/addButton.vue";

export default {
  data() {
    return {
      isLoading: true,
      objects: {},
      newObject: {},
      showDeleteModal: false,
      showEditModal: false,
      showAddModal: false,
      editingObject: {},
      addingObject: {},
      objectToDelete: {},
      attributes: {},
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  components: {
    Icon,
    Modal,
    Treeselect,
    SpimLoader,
    ContentTop,
    AddButton,
  },
  async created() {
    this.getObjects();
    this.getAttributes();
  },
  methods: {
    async createObject(data) {
      this.showAddModal = false;
      let vm = this;
      try {
        const response = await axios.post(
          API_BASE_URL + "/spim-categories",
          data
        );
        vm.objects = response.data;
        this.addingObject = {};
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Pomyślnie dodano kategorię",
        });
      } catch (error) {
        vm.$root.$emit("noticesBox", error.response.data);
      }
    },
    async updateObject(data) {
      this.showEditModal = false;
      this.$root.$emit("noticesBox", {
        type: "success",
        message:
          "Trwa zapisywanie kategorii. Za chwilę dane zostaną zaktualizowane.",
      });
      try {
        const response = await axios.put(
          API_BASE_URL + "/spim-categories/" + data.id,
          data
        );
        this.objects = response.data;
        this.editingObject = {};
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Pomyślnie zedytowano kategorię",
        });
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async getObjects() {
      this.isLoading = true;
      try {
        const response = await axios.get(API_BASE_URL + "/spim-categories");
        this.objects = response.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async getAttributes() {
      this.isLoading = true;
      try {
        const response = await axios.get(API_BASE_URL + "/attributes");
        this.attributes = response.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    async deleteObject(object) {
      this.showDeleteModal = true;
      this.objectToDelete = object;
    },
    async confirmDeleteObject() {
      this.showDeleteModal = false;
      try {
        await axios.delete(
          API_BASE_URL + "/spim-categories/" + this.objectToDelete.id
        );
        this.getObjects();
        this.$root.$emit("noticesBox", {
          type: "success",
          message: "Katagoria została usunięta",
        });
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    editObject(object) {
      this.showEditModal = true;
      this.editingObject = object;
      this.editingObject.attributes = this.changeObjectToArray(
        this.editingObject.attributes
      );
    },
    addObject() {
      this.showAddModal = true;
    },
    addOption() {
      this.editingObject.options.push(this.newOption);
      this.newOption = {};
    },
    deleteOption(option) {
      var index = this.editingObject.options.indexOf(option);
      this.editingObject.options.splice(index, 1);
    },
    editOption(option) {
      this.editingOption = option;
    },
    saveOption() {
      this.editingOption = {};
    },
    changeObjectToArray,
  },
};
</script>
