import Vue from 'vue';
import Vuex from 'vuex';

import {
    auth
} from './modules/auth.module';

import {
    language
} from './modules/language.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        language,
    }
});