<template>
  <div class="content">
    <Header
      :title="product.have_variants ? 'Edytuj wariant' : 'Edytuj produkt'"
      :subheading="
        product.activity
          ? `ostatnia zmiana ${$options.filters.prettyDate(
              product.activity.updated_at,
              true
            )} przez ${product.activity.user.email}`
          : ``
      "
      :subheading_second="
        product.id ? 'id#' + product.id + ', ' + product.name : ''
      "
    ></Header>
    <div class="content__body">
      <div class="language-menu">
        <div class="js-products-langs content-top__subwrap">
          <div
            class="d-flex justify-content-end language-switcher language-switcher--product"
          >
            <div
              class="language-switcher__lang"
              :class="{ 'is-active': $i18n.locale == lang.locale }"
              v-for="lang in translations"
              :key="'lang_' + lang.locale"
            >
              <div
                v-if="!lang.id"
                class="language-switcher__link"
                :key="'lang_' + lang.locale"
                @click="editOrCreateLang(lang, 'addTranslation', product)"
              >
                {{ lang.locale | uppercase }}
              </div>
              <div
                v-else
                class="language-switcher__link language-switcher__link--exist"
                :class="{ 'is-active': $i18n.locale == lang.locale }"
                :key="'lang_' + lang.locale"
                @click="editOrCreateLang(lang, 'productEdit', product)"
              >
                {{ lang.locale | uppercase }}
              </div>
            </div>
          </div>

          <div class="content-top__buttons">
            <button
              :class="[
                'button button--icon mr-2 ' +
                  (!canBeSaved ? 'button--grey' : ''),
              ]"
              @click.prevent="saveProduct()"
              :disabled="isSaveing || !canBeSaved"
            >
              <div
                class="loader button__icon button__icon--left"
                v-if="isSaveing"
              ></div>
              <icon
                v-else
                icon="save"
                icon-classes="button__icon button__icon--left"
              />
              <span
                class="button__text"
                v-text="isSaveing ? 'Zapisuję...' : 'Zapisz'"
                >Zapisz</span
              >
            </button>
            <button
              class="button button--icon button--red mr-2"
              @click.prevent="deleteProduct(product)"
              :disabled="isDeleting"
            >
              <div
                class="loader button__icon button__icon--left"
                v-if="isDeleting"
              ></div>
              <icon
                v-else
                icon="trash"
                icon-classes="button__icon button__icon--left"
              />
              <span
                class="button__text"
                v-text="isDeleting ? 'Usuwam...' : 'Usuń'"
                >Usuń</span
              >
            </button>
            <button
              v-if="product"
              :class="[
                'button button--icon button--' +
                  (!chekIfCanBeSync() || canBeSaved ? 'grey' : 'green'),
              ]"
              @click.prevent="syncProduct()"
              :disabled="!chekIfCanBeSync() || isSynchronized || canBeSaved"
            >
              <div
                class="loader button__icon button__icon--left"
                v-if="isSynchronized"
              ></div>
              <icon
                v-else
                icon="export"
                icon-classes="button__icon button__icon--left"
              />
              <span
                class="button__text"
                v-text="isSynchronized ? 'Synchronizuję...' : 'Synchronizuj'"
                >Synchronizuj</span
              >
            </button>
          </div>
          <div class="d-inline-flex ml-2">
            <span>Status synchronizacji</span>
            <div
              :class="[
                'items-table__status',
                ' ml-2',
                'items-table__status--' + wooSyncStatus(product),
              ]"
            ></div>
          </div>
        </div>
      </div>
      <div v-if="!isDeleted && product.have_variants != null">
        <div class="row">
          <div class="col-24 col-xl-8">
            <div class="product-form__input-wrap">
              <div class="product-form__title">Czy jest wariantem?</div>
              <label
                :for="'check-have_variants-' + product.id"
                class="product-form__checkbox"
              >
                <input
                  :id="'check-have_variants-' + product.id"
                  class="product-form__checkbox--input"
                  type="checkbox"
                  v-model="product.have_variants"
                  @change="
                    haveVariantsChange(), $root.$emit('productIsChanged')
                  "
                />
                <span
                  class="product-form__checkbox-checkmark"
                  v-text="product.have_variants ? 'Tak' : 'Nie'"
                ></span>
              </label>
            </div>
          </div>

          <div class="col-24">
            <tabs>
              <tab title="Dane podstawowe" :show="true">
                <div class="row">
                  <div class="col-24">
                    <BasicInfo
                      v-if="dictionaries"
                      :product="product"
                      :isDefaultLanguage="isDefaultLanguage"
                      :dictionaries="dictionaries"
                      :isLoading="isLoading"
                    >
                    </BasicInfo>
                  </div>
                </div>
              </tab>
              <tab title="Atrybuty" :show="true">
                <div class="row">
                  <div class="col-24">
                    <Attributes
                      v-if="dictionaries"
                      :product="product"
                      :isDefaultLanguage="isDefaultLanguage"
                      :dictionaries="dictionaries"
                      @changeSpimCategory="getSpimCategoryAttributes"
                    >
                    </Attributes>
                  </div>
                </div>
              </tab>
              <tab
                title="Produkty"
                :show="product.have_variants ? true : false"
              >
                <div class="row">
                  <div class="col-24">
                    <Variants
                      v-if="dictionaries"
                      :product="product"
                      :isDefaultLanguage="isDefaultLanguage"
                      :dictionaries="dictionaries"
                      :variantAttributesNew="variantAttributes"
                    >
                    </Variants>
                  </div>
                </div>
              </tab>

              <tab title="Zdjęcia" :show="true">
                <div class="row">
                  <div class="col-24">
                    <Photos
                      v-if="product.main_photo"
                      :photos="[product.main_photo]"
                      :multiplePhotos="false"
                      @delete="deletePhoto"
                      folderPath="main_photo/"
                      labelName="Zdjęcie główne"
                      @add="addPhoto"
                      type="main_photo"
                    ></Photos>
                  </div>
                  <div class="col-24">
                    <Photos
                      v-if="product.gallery"
                      :photos="product.gallery"
                      :multiplePhotos="true"
                      @delete="deletePhoto"
                      folderPath="gallery/"
                      labelName="Galeria"
                      type="gallery"
                    ></Photos>
                  </div>
                  <div class="col-24">
                    <TextareaSimple
                      label="Kod zdjęcia 360"
                      fieldName="photo_360"
                      :object="product"
                    ></TextareaSimple>
                  </div>
                </div>
              </tab>
              <tab title="Pliki" :show="true">
                <div class="row">
                  <div class="col-24">
                    <Files v-if="product" :product="product"></Files>
                  </div>
                </div>
              </tab>
              <tab title="Tłumaczenia" :show="product.locale != 'pl'">
                <div class="row">
                  <div class="col-24">
                    <Translations
                      :product="product"
                      :isDefaultLanguage="isDefaultLanguage"
                      :dictionaries="dictionaries"
                      :isLoading="isLoading"
                    >
                    </Translations>
                  </div>
                </div>
              </tab>
            </tabs>
          </div>
        </div>
      </div>

      <div v-if="isDeleted">
        Trwa usuwanie wariantu... Za 2 sekundy, zostaniesz przekierowany na
        listę produktów.
      </div>
    </div>
    <!-- Modal delete product -->
    <modal
      :show="showModal"
      size="md"
      @close="showModal = !showModal"
      :redirect="redirect"
    >
      <div slot="header" class="notice-modal__header--title">
        Usuwanie wariantu
      </div>

      <div slot="body">
        <div>
          Czy napewno chcesz usunąć produkt
          <strong>{{
            productToDelete.index + " - " + productToDelete.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDeleteProduct()"
        >
          Tak
        </button>
      </div>
    </modal>

    <!-- Modal save product -->
    <modal
      :show="showSaveModal"
      size="md"
      @close="showSaveModal = !showSaveModal"
      :redirect="redirect"
    >
      <div slot="header" class="notice-modal__header--title">
        Zapisywanie wariantu
      </div>

      <div slot="body">
        <div>
          Produkt zostanie automatycznie zapisany za
          {{ timerOutput }}.
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import BasicInfo from "./product-modules/BasicInfo.vue";
import Translations from "./product-modules/Translations.vue";
import Attributes from "./product-modules/Attributes.vue";
import Variants from "./product-modules/Variants.vue";
import Photos from "./product-modules/Photos";
import Files from "./product-modules/Files";
import Icon from "./parts/Icon";
import Modal from "./parts/Modals/Modal";
import jQuery from "jquery";
import { wooSyncStatus } from "../helpers/statusWoo";
import { changeObjectToArray } from "../helpers/objectToArray";
import { editOrCreateLang } from "../helpers/editOrCreateLang";
import TextareaSimple from "./product-modules/forms/TextareaSimple.vue";
import Tabs from "./parts/Tabs.vue";
import Tab from "./parts/Tab.vue";
import Header from "./parts/Header.vue";
import { ref } from "vue";

export default {
  data() {
    return {
      isLoading: true,
      canBeSaved: false,
      product: {
        variants: [],
      },
      variantAttributes: [],
      isLoaded: false,
      scrolled: false,
      isDeleting: false,
      isSaveing: false,
      isSynchronized: false,
      translations: [],
      productToDelete: {},
      showModal: false,
      showSaveModal: false,
      redirect: {},
      isDeleted: false,
      isDefaultLanguage: true,
      dictionaries: null,
      selectedLang: {
        locale: this.$i18n.locale,
      },
      countDownToTime: this.endDate(),
      timerOutput: null,
      wasShowSaveModal: false,
    };
  },
  components: {
    Translations,
    BasicInfo,
    Icon,
    Modal,
    Tabs,
    Tab,
    Attributes,
    Variants,
    Photos,
    Files,
    TextareaSimple,
    Header,
  },
  created() {
    this.getProduct();
    this.getDictionaries();
  },
  mounted() {
    setInterval(() => {
      this.startTimer();
    }, 1000);

    this.$root.$on("addProductModalSuccess", () => {
      this.translations = this.product.all_languages;
    });
    this.$root.$on("addProductLanguageModalSuccess", () => {
      this.getProduct();
    });
    this.$root.$on("productIsChanged", () => {
      this.productIsChanged();
    });

    let boxLang = jQuery(".js-products-langs"),
      sidebar = jQuery(".sidebar");

    if (boxLang.length != 0) {
      let topBoxLang = boxLang.offset().top + 125;

      var scroll = jQuery(window).scrollTop();
      if (scroll > topBoxLang) {
        sidebar.addClass("sidebar--open");
      } else {
        sidebar.removeClass("sidebar--open");
      }

      jQuery(window).scroll(function () {
        var scroll = jQuery(window).scrollTop();

        if (scroll > topBoxLang) {
          sidebar.addClass("sidebar--open");
        } else {
          sidebar.removeClass("sidebar--open");
        }
      });
    }
  },
  beforeDestroy() {
    this.$root.$off("addProductModalSuccess");
    this.$root.$off("addProductLanguageModalSuccess");
    this.$root.$off("productIsChanged");
    clearInterval(this.timer);
  },
  methods: {
    haveVariantsChange() {
      if (!this.product.have_variants) {
        this.product.variants = [];

        //add new attributes
        this.product.attributes = this.product.attributes.concat(
          this.variantAttributes
        );
      } else {
        this.product.attributes = this.product.attributes.filter(
          (attribute) => {
            return attribute.show_in_variants == 0;
          }
        );
      }
    },
    startTimer: function () {
      const timeNow = new Date().getTime();
      const timeDifference = this.countDownToTime - timeNow;
      const millisecondsInOneSecond = 1000;
      const millisecondsInOneMinute = millisecondsInOneSecond * 60;
      const millisecondsInOneHour = millisecondsInOneMinute * 60;
      const differenceInHours =
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);
      const differenceInMinutes =
        (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
      const differenceInSeconds =
        (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
      const hours = Math.floor(differenceInHours);
      const minutes = Math.floor(differenceInMinutes);
      const seconds = Math.floor(differenceInSeconds);
      this.timerOutput =
        (minutes <= 9 ? "0" + minutes : minutes) +
        ":" +
        (seconds <= 9 ? "0" + seconds : seconds);

      if (
        hours == 0 &&
        (minutes == 1 || minutes == 0) &&
        !this.wasShowSaveModal
      ) {
        this.wasShowSaveModal = true;
        this.showSaveModal = true;
      }

      if (hours == 0 && minutes == 0 && seconds == 0) {
        this.saveProduct();
        this.showSaveModal = false;
      }
      if (hours < 0 && minutes < 0 && seconds <= -10) {
        this.$store.dispatch("auth/logout", this.$store.state.auth.user).then(
          () => {
            this.$router.push("/login");
          },
          (error) => {
            this.loading = false;
            this.message = error.message;
          }
        );
      }
    },
    endDate() {
      const user = localStorage.getItem("user");
      let userJson = JSON.parse(user);
      return new Date(userJson.valid_until).getTime() - 30000;
    },
    chekIfCanBeSync() {
      return true;
    },
    productIsChanged() {
      this.canBeSaved = true;
    },
    async getSpimCategoryAttributes(variantsAttributes, allAttributes) {
      try {
        const response = await axios.get(
          API_BASE_URL + "/spim-categories/" + this.product.spim_category_id
        );
        this.variantAttributes = response.data.variant_attributes;

        if (variantsAttributes) {
          this.setVariantAttributes(response.data.variant_attributes);
        }
        if (allAttributes && !this.product.have_variants) {
          this.setAttributes(response.data.attributes);
        }
        if (allAttributes && this.product.have_variants) {
          this.setAttributes(response.data.no_variant_attributes);
        }
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    setAttributes(attributes) {
      this.product.attributes = attributes;
      this.product.attributes.forEach((attribute) => {
        attribute.option_value_string = null;
        attribute.option_value_ids = null;
        attribute.option_value_boolean = null;
        attribute.spim_category_id = attribute.pivot.spim_category_id;
        attribute.is_translated_option_value_string = 0;
      });
    },
    setVariantAttributes(attributes) {
      this.product.variants.forEach((variant) => {
        variant.attributes = [...attributes];
        variant.attributes.forEach((attribute) => {
          attribute.option_value_ids = null;
          attribute.option_value_boolean = null;
          attribute.option_value_string = null;
          attribute.spim_category_id = attribute.pivot.spim_category_id;
          attribute.is_translated_option_value_string = 0;
        });
      });
    },
    addPhoto(photo, indexOfContent, type) {
      this.product[type] = photo;
    },
    deletePhoto(photo, indexOfContent, type) {
      if (type == "gallery") {
        const filtersList = this.product.gallery.filter(
          (element) => element !== photo
        );
        this.product.gallery = filtersList;
      } else {
        this.product[type] = { id: null, path: null, type: type };
      }
    },
    wooSyncStatus,
    getDictionaries() {
      axios
        .get(API_BASE_URL + "/dictionaries")
        .then((response) => {
          this.dictionaries = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.$root.$emit("noticesBox", error.response.data);
          this.isLoading = false;
        });
    },
    getProduct() {
      let vm = this;
      axios
        .get(API_BASE_URL + "/products/" + vm.$route.params.id)
        .then((response) => {
          vm.product = ref(response.data);
          vm.translations = vm.product.all_languages;
          if (vm.$i18n.locale != vm.product.locale) {
            vm.$store.dispatch("language/setLanguage", response.data.language);
          }

          if (vm.product.spim_category_id) {
            this.getSpimCategoryAttributes(false, false);
          }

          vm.product.markets = this.changeObjectToArray(vm.product.markets);
        })
        .catch((error) => {
          vm.$root.$emit("noticesBox", error.response.data);
        });
    },
    async syncProduct() {
      this.isSynchronized = true;
      var data = {
        products: [this.product.id],
      };
      axios
        .post(API_BASE_URL + "/synchronize-woo", data)
        .then((response) => {
          this.$root.$emit("noticesBox", response.data);
          this.isSynchronized = false;
          this.getProduct();
        })
        .catch((error) => {
          this.isSynchronized = false;
          this.$root.$emit("noticesBox", error.response.data);
          this.getProduct();
        });
    },
    updateProduct(product) {
      this.product = product;
      this.product.markets = this.changeObjectToArray(this.product.markets);
    },
    saveProduct() {
      this.isSaveing = true;
      //change filds to null if have variants
      //zastanowić się czy nie przenieść tego
      //dodać jeszcze na backend
      if (this.product.have_variants) {
        this.product.index = null;
        this.product.ean13 = null;
        this.product.is_new = false;
        this.product.length = null;
        this.product.width = null;
        this.product.height = null;
        this.product.weight = null;
        this.product.inner_box = null;
        this.product.outer_box = null;
        this.product.palette = null;
        this.product.packaging_id = null;
      }
      let data = JSON.parse(JSON.stringify(this.product));
      data.similar_products = this.changeObjectToArray(data.similar_products);

      axios
        .put(API_BASE_URL + "/products/" + this.product.id, data)
        .then((response) => {
          this.$root.$emit("noticesBox", {
            type: "success",
            message: "Poprawnie zapisano dane wariantu.",
          });
          this.updateProduct(response.data);
          this.canBeSaved = false;
          this.isSaveing = false;
        })
        .catch((error) => {
          this.$emit("storingProduct", false);
          this.$root.$emit("noticesBox", error.response.data);
          this.isSaveing = false;
        });
    },
    handleSelectLang(node) {
      this.selectedLang = {
        locale: this.$i18n.locale,
      };

      if (node.product_id && node.product_id != this.product.id) {
        this.editOrCreateLang(node, "productEdit", this.product);
      } else {
        this.editOrCreateLang(node, "addTranslation", this.product);
      }
    },
    async deleteProduct(product) {
      this.showModal = true;
      this.productToDelete = product;
    },
    confirmDeleteProduct() {
      let vm = this;
      this.showModal = false;
      this.isDeleting = true;
      axios
        .delete(API_BASE_URL + "/products/" + vm.productToDelete.id)
        .then(() => {
          vm.isDeleted = setTimeout(() => {
            this.isDeleting = false;
            vm.$router.push({
              name: "productsList",
            });
          }, 2000);
        });
    },
    changeObjectToArray,
    editOrCreateLang,
  },
};
</script>
