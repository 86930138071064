<template>
  <div class="product-form__input-wrap">
    <div class="product-form__title">{{ label }}</div>
    <label
      :for="'check-' + fieldName + '-' + object.id"
      class="product-form__checkbox"
    >
      <input
        :id="'check-' + fieldName + '-' + object.id"
        class="product-form__checkbox--input"
        type="checkbox"
        v-model="object[fieldName]"
        @input="$root.$emit('productIsChanged')"
      />
      <span
        class="product-form__checkbox-checkmark"
        v-text="object[fieldName] ? 'Tak' : 'Nie'"
      ></span>
    </label>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    label: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    object: {
      type: Object,
      required: false,
    },
  },
};
</script>
