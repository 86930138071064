var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"spim-loader-container",class:{ 'is-loading': _vm.isLoading }},[_c('spim-loader'),(_vm.logs.data.length > 0)?_c('div',{staticClass:"content"},[_c('table',{staticClass:"items-table"},[_vm._m(0),_c('tbody',{staticClass:"items-table__tbody"},[_vm._l((_vm.logs.data),function(log){return [_c('tr',{key:log.id,staticClass:"items-table__tr"},[_c('td',{staticClass:"items-table__td"},[_vm._v(" "+_vm._s(log.id)+" ")]),_c('td',{staticClass:"items-table__td"},[_c('div',{staticClass:"form-box__mobile-element"},[_vm._v("Data")]),_vm._v(" "+_vm._s(_vm._f("prettyDate")(log.updated_at,true))+" ")]),_c('td',{staticClass:"items-table__td"},[_c('div',{staticClass:"form-box__mobile-element"},[_vm._v("Użytkownik")]),_c('span',{domProps:{"textContent":_vm._s(
                    log.user
                      ? log.user.email
                      : _vm.getEmail(log)
                      ? _vm.getEmail(log)
                      : '-'
                  )}})]),_c('td',{staticClass:"items-table__td"},[_c('div',{staticClass:"form-box__mobile-element"},[_vm._v("Akcja")]),_vm._v(" "+_vm._s(log.description)+" ")]),_c('td',{staticClass:"items-table__td"},[_c('div',{staticClass:"form-box__mobile-element"},[_vm._v("Obiekt")]),_c('span',{domProps:{"textContent":_vm._s(
                    log.subject_id != null
                      ? log.subject_type + '#' + log.subject_id
                      : log.subject_type
                      ? log.subject_type
                      : '-'
                  )}})]),_c('td',{staticClass:"items-table__td pr-0"},[_c('div',{staticClass:"form-box__mobile-element"},[_vm._v("Akcje")]),_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.hasComparison(log))?_c('button',{staticClass:"button-icon",on:{"click":function($event){return _vm.showComparison(log)}}},[_c('icon',{attrs:{"icon":"preview"}})],1):_c('button',{staticClass:"button-icon",on:{"click":function($event){return _vm.showPreview(log)}}},[_c('icon',{attrs:{"icon":"preview"}})],1)])])])]})],2)])]):_c('div',{staticClass:"content"},[_vm._v("Brak wyników.")])],1),(_vm.havePagination)?_c('pagination',{attrs:{"pagination":_vm.logs,"offset":1},on:{"paginate":function($event){return _vm.$emit('getObjects')}}}):_vm._e(),_c('modal',{attrs:{"show":_vm.showModal,"size":"lg"},on:{"close":function($event){_vm.showModal = !_vm.showModal}}},[_c('div',{staticClass:"notice-modal__header--title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Podgląd logu #"+_vm._s(_vm.previewLog.id)+" ")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[(_vm.previewLog.preview)?_c('div',{staticClass:"row"},[_c('vue-json-pretty',{attrs:{"showLength":true,"data":_vm.previewLog.preview,"highlightMouseoverNode":true}})],1):_c('div',{staticClass:"row"},[(_vm.previewLog.old)?_c('div',{staticClass:"col-24 col-xl-12"},[_c('span',{domProps:{"textContent":_vm._s(_vm.previewLog.new ? 'Przed:' : '')}}),_c('vue-json-pretty',{attrs:{"showLength":true,"data":_vm.previewLog.old,"highlightMouseoverNode":true}})],1):_vm._e(),(_vm.previewLog.new)?_c('div',{staticClass:"col-24 mt-5 mt-xl-0 col-xl-12"},[_c('span',{domProps:{"textContent":_vm._s(_vm.previewLog.old ? 'Po:' : '')}}),_c('vue-json-pretty',{attrs:{"showLength":true,"data":_vm.previewLog.new,"highlightMouseoverNode":true}})],1):_vm._e()])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"items-table__thead"},[_c('tr',{staticClass:"items-table__tr"},[_c('th',{staticClass:"items-table__th"},[_vm._v("#")]),_c('th',{staticClass:"items-table__th"},[_vm._v("Data")]),_c('th',{staticClass:"items-table__th"},[_vm._v("Użytkownik")]),_c('th',{staticClass:"items-table__th"},[_vm._v("Akcja")]),_c('th',{staticClass:"items-table__th"},[_vm._v("Obiekt")]),_c('th',{staticClass:"items-table__th pr-0 text-right"},[_vm._v("Akcje")])])])
}]

export { render, staticRenderFns }