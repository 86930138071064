<template>
  <tr>
    <td
      class="items-table__td items-table__td--label items-table__td--gray"
      v-if="attributeLocal.show_in_variants"
    >
      <div class="form-box__mobile-element">Nazwa</div>
      {{ attributeLocal.name }}
    </td>
    <td
      class="items-table__td items-table__td--expand"
      v-if="attributeLocal.show_in_variants"
    >
      <div class="form-box__mobile-element">Wartość</div>
      <input
        v-if="attributeLocal.field_type == 'string'"
        type="text"
        class="items-table__input items-table__input--xl"
        v-model="attributeLocal.option_value_string"
        @change="
          $root.$emit('productIsChanged');
          setNewAttribute(attributeLocal);
        "
      />
      <label
        v-else-if="attributeLocal.field_type == 'boolean'"
        :for="
          'check-attribute-' +
          attributeLocal.id +
          '-var-index' +
          (indexVariant + 1)
        "
        class="product-form__checkbox"
      >
        <input
          :id="
            'check-attribute-' +
            attributeLocal.id +
            '-var-index' +
            (indexVariant + 1)
          "
          class="product-form__checkbox--input"
          type="checkbox"
          v-model="attributeLocal.option_value_boolean"
          @change="
            $root.$emit('productIsChanged');
            setNewAttribute(indexAttr);
          "
        />
        <span
          class="product-form__checkbox-checkmark"
          v-text="attributeLocal.option_value_boolean ? 'Tak' : 'Nie'"
        ></span>
      </label>
      <label v-else-if="attributeLocal.field_type == 'select'" class="mb-0">
        <treeselect
          v-if="attributeLocal.options && attributeLocal.options.length > 0"
          class="vue-treeselect"
          v-model="attributeLocal.option_value_ids"
          :multiple="attributeLocal.multi_select ? true : false"
          :options="attributeLocal.options"
          placeholder=""
          :normalizer="normalizer"
          :clearable="true"
          @input="
            $root.$emit('productIsChanged');
            setNewAttribute(indexAttr);
          " />
        <div
          v-else
          class="product-form__note"
          v-text="
            isLoading
              ? 'Ładowanie...'
              : 'Nie dodano jeszcze opcji do tego atrybutu.'
          "
        ></div
      ></label>
    </td>
  </tr>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect";
import { ref } from "vue";

export default {
  data() {
    return {
      isLoading: false,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
      attributeLocal: ref({ ...this.attribute }),
    };
  },
  props: {
    attribute: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
    indexVariant: {
      type: Number,
      required: false,
    },
  },
  components: {
    Treeselect,
  },
  watch: {
    attribute: {
      handler() {
        this.attributeLocal = ref({ ...this.attribute });
      },
      deep: true,
    },
  },
  methods: {
    setNewAttribute() {
      this.$emit("set-new-attribute", this.attributeLocal, this.index);
    },
  },
};
</script>
