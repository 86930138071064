<template>
  <div class="filters">
    <h6 class="filters__title">Filtry</h6>

    <div class="row">
      <div class="col-24 mb-4">
        <Searcher
          inputClass="simple-search__input--full"
          @searchObjects="searchObjects"
          @getObjects="getObjects"
        ></Searcher>
      </div>
      <div class="col-24 col-xl-8">
        <InputTreeselect
          :options="options.categories"
          :object="filters"
          :isLoading="true"
          :multiple="true"
          valueConsistsOf="BRANCH_PRIORITY"
          :disableBranchNodes="false"
          :clearable="false"
          fieldName="category_ids"
          label="Kategorie"
        ></InputTreeselect>
      </div>
      <div class="col-24 col-xl-8">
        <InputTreeselect
          :options="options.lines"
          :object="filters"
          :isLoading="true"
          :multiple="true"
          fieldName="line_ids"
          label="Linia produktów"
        ></InputTreeselect>
      </div>
      <div class="col-24 col-xl-8">
        <InputTreeselect
          :options="options.languages"
          :object="filters"
          :isLoading="true"
          :multiple="true"
          fieldName="langualge_missing_ids"
          label="Brakujące języki"
        ></InputTreeselect>
      </div>
      <div class="col-24 col-xl-8">
        <InputTreeselect
          :options="[
            { id: 'variants', name: 'Warianty z produktami' },
            { id: 'spimple_products', name: 'Produkty proste' },
          ]"
          :object="filters"
          :isLoading="true"
          :multiple="false"
          :clearable="true"
          fieldName="products_type"
          label="Typ wariantu/produktu"
        ></InputTreeselect>
      </div>
    </div>
    <div class="col-24 pr-0">
      <AddButton
        textButton="Wyszukaj"
        buttonClass="button--right"
        @addObject="getObjects()"
      ></AddButton>
    </div>
  </div>
</template>
<script>
import InputTreeselect from "./../product-modules/forms/InputTreeselect.vue";
import AddButton from "./buttons/addButton.vue";
import Searcher from "./Forms/Searcher.vue";
export default {
  data() {
    return {
      normalizer(node) {
        let data = {
          id: node.id,
          label: node.name,
        };
        if (node.subcategories && node.subcategories.length > 0) {
          data["children"] = node.subcategories;
        }
        return data;
      },
    };
  },
  props: {
    options: {
      type: Object,
      require: true,
    },
    filters: {
      type: Object,
      require: true,
    },
  },
  components: {
    InputTreeselect,
    AddButton,
    Searcher,
  },
  mounted() {},
  methods: {
    getObjects() {
      this.$emit("getObjects", true);
    },
    searchObjects(search) {
      this.$emit("searchObjects", search);
    },
  },
};
</script>
