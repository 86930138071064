<template>
  <div class="product-form__input-wrap">
    <div class="product-form__title">{{ label }}</div>
    <textarea
      class="product-form__input product-form__input--textarea"
      v-model="object[fieldName]"
      @change="$root.$emit('productIsChanged')"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    label: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    object: {
      type: Object,
      required: false,
    },
  },
};
</script>
