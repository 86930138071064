<template>
  <div class="mr-2">
    <button
      :class="['button button--icon', buttonClass]"
      @click="$emit('addObject')"
      :disabled="disabledAdditionalButton"
    >
      <icon
        v-if="icon"
        icon="plus"
        icon-classes="button__icon button__icon--left icon--white icon__plus--small"
      />
      <span class="button__text">{{ textButton }}</span>
    </button>
  </div>
</template>
<script>
import Icon from "../Icon.vue";

export default {
  components: {
    Icon,
  },
  props: {
    textButton: {
      type: String,
      require: true,
    },
    icon: {
      type: Boolean,
      require: true,
    },
    disabledAdditionalButton: {
      type: Boolean,
      require: true,
    },
    show: {
      type: Boolean,
      require: false,
    },
    changeTextButton: {
      type: String,
      require: true,
    },
    buttonClass: {
      type: String,
      require: true,
    },
  },
};
</script>
