<template>
  <div class="notices">
    <transition-group name="transition-alert">
      <div
        class="notices__box"
        :class="'notices__box--' + (data.type ? data.type : 'default')"
        v-for="(data, index) in boxes"
        :key="'notice_' + index"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-24">
              <div class="notices__alert">
                {{ data.message }}
                <ul v-if="data.errors" class="notices__list">
                  <li
                    v-for="(error, index) in data.errors"
                    :key="'error_' + index"
                  >
                    <template v-for="text in error">{{ text }} </template>
                  </li>
                </ul>
                <div class="notices__close">
                  <button
                    class="button-icon button-icon--borderless"
                    @click="removeNotice(index)"
                  >
                    <icon icon="delete" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import Icon from "../parts/Icon";

export default {
  data() {
    return {
      boxes: [],
    };
  },
  components: {
    Icon,
  },
  mounted() {
    this.$root.$on("noticesBox", (data) => {
      this.boxes.push(data);

      setTimeout(() => this.boxes.splice(this.boxes.indexOf(data), 1), 5000);
    });
  },
  beforeDestroy() {
    this.$root.$off("noticesBox");
  },
  methods: {
    removeNotice(index) {
      this.boxes.splice(index, 1);
    },
  },
};
</script>
