var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[(_vm.attributeLocal.show_in_variants)?_c('td',{staticClass:"items-table__td items-table__td--label items-table__td--gray"},[_c('div',{staticClass:"form-box__mobile-element"},[_vm._v("Nazwa")]),_vm._v(" "+_vm._s(_vm.attributeLocal.name)+" ")]):_vm._e(),(_vm.attributeLocal.show_in_variants)?_c('td',{staticClass:"items-table__td items-table__td--expand"},[_c('div',{staticClass:"form-box__mobile-element"},[_vm._v("Wartość")]),(_vm.attributeLocal.field_type == 'string')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attributeLocal.option_value_string),expression:"attributeLocal.option_value_string"}],staticClass:"items-table__input items-table__input--xl",attrs:{"type":"text"},domProps:{"value":(_vm.attributeLocal.option_value_string)},on:{"change":function($event){_vm.$root.$emit('productIsChanged');
        _vm.setNewAttribute(_vm.attributeLocal);},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.attributeLocal, "option_value_string", $event.target.value)}}}):(_vm.attributeLocal.field_type == 'boolean')?_c('label',{staticClass:"product-form__checkbox",attrs:{"for":'check-attribute-' +
        _vm.attributeLocal.id +
        '-var-index' +
        (_vm.indexVariant + 1)}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attributeLocal.option_value_boolean),expression:"attributeLocal.option_value_boolean"}],staticClass:"product-form__checkbox--input",attrs:{"id":'check-attribute-' +
          _vm.attributeLocal.id +
          '-var-index' +
          (_vm.indexVariant + 1),"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.attributeLocal.option_value_boolean)?_vm._i(_vm.attributeLocal.option_value_boolean,null)>-1:(_vm.attributeLocal.option_value_boolean)},on:{"change":[function($event){var $$a=_vm.attributeLocal.option_value_boolean,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.attributeLocal, "option_value_boolean", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.attributeLocal, "option_value_boolean", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.attributeLocal, "option_value_boolean", $$c)}},function($event){_vm.$root.$emit('productIsChanged');
          _vm.setNewAttribute(_vm.indexAttr);}]}}),_c('span',{staticClass:"product-form__checkbox-checkmark",domProps:{"textContent":_vm._s(_vm.attributeLocal.option_value_boolean ? 'Tak' : 'Nie')}})]):(_vm.attributeLocal.field_type == 'select')?_c('label',{staticClass:"mb-0"},[(_vm.attributeLocal.options && _vm.attributeLocal.options.length > 0)?_c('treeselect',{staticClass:"vue-treeselect",attrs:{"multiple":_vm.attributeLocal.multi_select ? true : false,"options":_vm.attributeLocal.options,"placeholder":"","normalizer":_vm.normalizer,"clearable":true},on:{"input":function($event){_vm.$root.$emit('productIsChanged');
          _vm.setNewAttribute(_vm.indexAttr);}},model:{value:(_vm.attributeLocal.option_value_ids),callback:function ($$v) {_vm.$set(_vm.attributeLocal, "option_value_ids", $$v)},expression:"attributeLocal.option_value_ids"}}):_c('div',{staticClass:"product-form__note",domProps:{"textContent":_vm._s(
          _vm.isLoading
            ? 'Ładowanie...'
            : 'Nie dodano jeszcze opcji do tego atrybutu.'
        )}})],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }